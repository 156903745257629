@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

:root {
    --primario: #3ca93f;
    --primario-white: #d5ffd8;
    --primario-light: #39a93d1a;
    --primario-dark: #12971b;
    --secudario: #2683C3;
    --rojo: rgb(202, 50, 50);
    --amarillo: rgb(238, 192, 28);
    --verde: #318e2c;
    --verde-2: #3FD300;
    --azul: #2a95bf;
    --azul-2: #2a95bf3a;
    --blanco: #ffffff;
    --gris: #f0f2f586;
    --gris-2: #808080;
    --gris-3: #c0c0c022;
    --negro: #000;
    --bg-primario: #000;
    --primary-vending: #00b89a;
    --primary-vending: #0025B8;
    --text: #3c424d;
    --new-main-0: #3861FB;
    --new-main-1: #3866F2;
    --new-main-2: #E2E8FF;
    --new-main-3: #CAF238;
    --new-main-4: #C7FB37;
    --new-main-5: #E3F2AC;
    --new-main-6: #F2F2F2;
    --new-main-8: #B3B2B3;
    --new-frame: #F0F0F0;

    /* Texts Style */
    --fuenteh: 'PT Sans', sans-serif;
    --fuente-main: "Jersey 10", sans-serif;

    /*Title: Borders */
    --border-1: 9px;
    --border-2: 12px;
    --border-3: 14px;

    /*Title: Texts */
    --text--sm: 9px;
    --text--md: 12px;
    --text--lg: 14px;
    --text--xl: 20px;
}