/* *Generales  */
/* ::-webkit-scrollbar {
  display: none;
}  */
/* containers */

* {
   text-decoration: none;
   list-style: none;
}

body {
   font-family: "Nunito", sans-serif;
   overflow-x: hidden;
   scrollbar-width: thin;
}

body:not(.modal-open) {
   padding-right: 0px !important;
}


.smart-pay-main {
   margin-top: 5.2rem;
}

.branch-container {
   display: flex;
   height: 100vh;
}

.container-header-sp {
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-direction: row;
}

.filter-sp-trn {
   width: 200px;
}

@media (width < 764px) {

   .container-header-sp {
      display: flex;
      flex-direction: column;
   }

   .filter-sp-trn {
      width: 200px;
      margin-top: 15px;
   }
}

.rotated-icon {
   transform: rotate(45deg);
   -webkit-transform: rotate(45deg);
   -moz-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   -o-transform: rotate(45deg);
}


.pdf-viewer-sp {
   width: 100%;
   height: 90vh;
}

.rounded-sp {
   border-radius: 20px;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   -ms-border-radius: 20px;
   -o-border-radius: 20px;
}

.main-orders-views {
   max-width: 29rem;
   margin: 0 auto;
   padding: 0 1rem;
}

/* Version Tablet */
@media only screen and (max-width: 576px) {
   .main-orders-views {
      max-width: 24rem;
      margin: 0 auto;
      padding: 0 1rem;
   }
}

.orders-wrapper {
   border: 1px solid var(--gris);
   height: 472px;
   width: 100%;
   overflow-y: scroll;
   margin: 5px 0;
   border-radius: var(--border-3);
   -webkit-border-radius: var(--border-3);
   -moz-border-radius: var(--border-3);
   -ms-border-radius: var(--border-3);
   -o-border-radius: var(--border-3);
}

/* ?Texts  */
.font-sp-main {
   font-size: 24px;
   font-family: var(--fuente-main);
}

.voucher-container {
   height: 100%;
   width: 2;
   font-family: monospace;
   background-color: #f0f0f0;
   padding: 20px;
   border: 1px solid #ccc;
}

.voucher-text {
   white-space: pre-wrap;
   font-size: 2rem;
   line-height: 1.5;
   margin: 0;
   text-align: justify;
   text-justify: inter-word;
}

.text-title {
   font-size: 41px;
}

.text-color-sp {
   background: var(--primary-2);
   background: linear-gradient(90deg, rgba(57, 169, 61, 1) 28%, rgba(42, 149, 191, 1) 77%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   color: rgb(71, 246, 255);
}

.text-primary-sp {
   color: var(--primario);
}

.text-secondary-sp {
   color: var(--azul);
}

/* ?BackGround */
.bg-primary {
   background-color: var(--primary-1);
}

.bg-secondary {
   background-color: var(--primary-2);
}

.bg-gray {
   background-color: var(--gris-3);
}

.bg-color-sp {
   color: var(--blanco);
   background: var(--primary-1);
   background: linear-gradient(130deg, rgba(57, 169, 61, 1) 28%, rgba(42, 149, 191, 1) 77%);
}

.bg-color-sp:hover {
   color: var(--blanco);
   background: var(--azul);
}

.bg-color-sp-opaco {
   background: rgb(57, 169, 61);
   background: linear-gradient(130deg, rgba(57, 169, 61, 0.29) 28%, rgba(42, 149, 191, 0.29039662973040103) 77%);
}


.bg-white-sp {
   background-color: #ffffff;
}

/* ?Borders  */
.border-sp {
   border-radius: var(--border-2);
   -webkit-border-radius: var(--border-2);
   -moz-border-radius: var(--border-2);
   -ms-border-radius: var(--border-2);
   -o-border-radius: var(--border-2);
}

.border-inset {
   -webkit-box-shadow: inset 1px -1px 10px -5px rgba(0, 0, 0, 0.33);
   -moz-box-shadow: inset 1px -1px 10px -5px rgba(0, 0, 0, 0.33);
   box-shadow: inset 1px -1px 10px -5px rgba(0, 0, 0, 0.33);
}

/* ?Buttons */
.btn-sp {
   color: var(--primario);
   font-weight: 700;
}

.btn-sp:hover {
   background-color: var(--azul-2);
   color: var(--azul);
   font-weight: 700;
}

.btn-smartp {
   color: var(--primario);
   font-size: 17px;
   font-weight: 700;
}

.btn-smartp:hover {
   color: var(--primario);
   background-color: var(--primario-light);

}

.btn-secondary-sp {
   color: var(--gris-2);
   font-size: 17px;
   font-weight: 700;
}

.btn-secondary-sp:hover {
   color: var(--primario);
   background-color: var(--primario-light);
}

/* *Pages Sections   */
/* ?Navigation Top Bar  */

/* NavBar Top */

.navbar {
   position: fixed;
   z-index: 1;
   top: 0;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   height: 60px;
   padding: 0 20px;
   background: var(--blanco);
   color: #19191c;
}

.navbar-logo {
   display: flex;
   align-items: center;
   gap: 1px;
   /* width: 160px; */

}

.hrline {

   border: none;
   border-left: 1px solid hsla(200, 10%, 50%, 100);
   height: 95%;
   width: 10px;
}


.navbar-logo-icon {
   height: 48px;
}

.navbar-logo-post {
   height: 35px;
}

/*? New NavBar  */
.header-nav-sp {
   position: fixed;
   width: 100%;
   top: 0;
   right: 0;
   z-index: 1000;
   display: flex;
   align-items: center;
   justify-content: space-between;
   background-color: var(--blanco);
   padding: 10px 2%;
   transition: all .50s ease;
   -webkit-transition: all .50s ease;
   -moz-transition: all .50s ease;
   -ms-transition: all .50s ease;
   -o-transition: all .50s ease;
   border: var(--new-frame) solid 1px;
}

.header-nav-sp>.logo {
   display: flex;
   align-items: center;
}

.navbar-logo {
   display: flex;
   align-items: center;
}

.navbar-logo-text {
   font-weight: 700;
   font-family: var(--fuente-main);
   display: none;
   font-size: 1.2rem;
}

.user-name-log {
   cursor: pointer;
   font-weight: 700;
}

.navbar-logo-text-sp {
   color: var(--primario);
   font-size: 1.7rem;
   font-weight: 700;
   font-family: var(--fuente-main);
}

.nabvar-sp {
   display: flex;
}


.nabvar-sp span {
   display: flex;
   align-items: center;
   color: var(--primario-dark);
   font-size: 1.1rem;
   font-weight: 500;
   padding: 0px 0;
   margin: 0px 30px;
   cursor: pointer;
   text-decoration: none;
   transition: all .50s ease;
   -webkit-transition: all .50s ease;
   -moz-transition: all .50s ease;
   -ms-transition: all .50s ease;
   -o-transition: all .50s ease;
}

.nabvar-sp a {
   color: var(--primario);
   font-weight: 500;
   text-decoration: none;
}

.nabvar-sp a:hover {
   color: var(--primario-dark);
   cursor: pointer;

}


.main-sp {
   display: flex;
   align-items: center;

}


.main-sp span {
   text-decoration: none;
   margin-right: 0px;
   margin-left: 0px;
   color: var(--primario);
   font-size: 1.1rem;
   font-weight: 700;
   transition: all .50s ease;
}

#menu-icon {
   font-size: 30px;
   padding: 0px 2px;
   color: var(--negro);
   cursor: pointer;
   z-index: 10001;
   display: none;
}


@media (width > 1190px) {

   .navbar-logo-text-sp {
      color: var(--primario);
      font-size: 1.2rem;
      font-weight: 600;
   }

   .nabvar-sp-login-out {
      position: absolute;
      top: 80%;
      right: -100%;
      width: 270px;
      height: auto;
      background-color: var(--blanco);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border: var(--new-frame) solid 2px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      transition: all .50s ease;
      -webkit-transition: all .50s ease;
      -moz-transition: all .50s ease;
      -ms-transition: all .50s ease;
      -o-transition: all .50s ease;
   }

   .nabvar-sp-login-out span {
      padding: 10px 0px;

   }

   .open-log-out {
      right: 2%;
   }

   .unselectable {
      user-select: none;
   }

}




@media (max-width: 1280px) {
   .header-nav-sp {
      padding: 5px 2%;
      transition: all .2s ease;
      -webkit-transition: all .2s ease;
      -moz-transition: all .2s ease;
      -ms-transition: all .2s ease;
      -o-transition: all .2s ease;
      border: var(--new-frame) solid 1px;
   }

   .nabvar-sp span {
      padding: 5px 0;
      margin: 2px 10px;
      color: var(--verde);

   }

}

@media (width < 1190px) {

   /* .navbar-logo-text-sp {
    color: var(--primario);
    font-size: 1.2rem;
    font-weight: 600;
  } */

   .nabvar-sp {
      position: absolute;
      top: 80%;
      right: -100%;
      width: 270px;
      height: auto;
      background-color: var(--blanco);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      transition: all .50s ease;
      -webkit-transition: all .50s ease;
      -moz-transition: all .50s ease;
      -ms-transition: all .50s ease;
      -o-transition: all .50s ease;
      border: var(--new-frame) solid 2px;
   }

   .nabvar-sp span {
      display: block;
      transition: all .50s ease;
      -webkit-transition: all .50s ease;
      -moz-transition: all .50s ease;
      -ms-transition: all .50s ease;
      -o-transition: all .50s ease;

   }



   .open-menu {
      right: 2%;
   }


   #menu-icon {
      display: block;
   }

   .close-log-out {

      display: none;
   }

}


/*? End  New NavBar  */

/*? New Login SP */

.main-login-sp {
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 100vh;
   background-color: var(--new-frame);
   place-items: center;
   background-repeat: repeat;
   background-size: cover;
   margin: 0;
   background-image: url("../src/components/img/backgrounds/bgsp00.svg");

}

.form-signin-sp {
   height: 100%;
   width: 450px;
   padding: 1px 20px;
   /* padding: 30px 40px; */
   border: 2px solid #d5ffd8;
   border-radius: 20px;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   -ms-border-radius: 20px;
   -o-border-radius: 20px;
   transition: all .50s ease;
   -webkit-transition: all .50s ease;
   -moz-transition: all .50s ease;
   -ms-transition: all .50s ease;
   -o-transition: all .50s ease;
}

.bottom-container-footer {
   position: fixed;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 50px;

   background-color: transparent;
   /* Color de fondo deseado */
   color: var(--negro);
   /* Color de texto deseado */
   z-index: 999;
   /* Ajusta el valor de z-index según tu necesidad para controlar la superposición */
}

.top-container-sp {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   font-weight: 700;
   height: auto;
   background-color: transparent;
   /* Color de fondo deseado */
   color: var(--primario);
   /* Color de texto deseado */
   z-index: 999;
   /* Ajusta el valor de z-index según tus necesidades para controlar la superposición */
}

@media (width < 520px) {
   .form-signin-sp {
      width: 95%;
      padding: 30px 40px;
      /* border: 1px solid var(--new-frame); */

   }

}

@media (width > 1820px) {
   .form-signin-sp {
      width: 545px;
      padding: 30px 40px;
      /* border: 1px solid var(--new-frame); */

   }

}

.form-signin-sp .form-floating:focus-within {
   color: var(--primary);
   z-index: 2;
   box-shadow: var(--primary);
}

.form-signin-sp input[type="email"] {
   color: var(--primary);
   margin-bottom: -1px;
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0;
}

.form-signin-sp input[type="password"],
input[type="email"] {
   color: var(--primary);
   margin-bottom: 10px;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
   box-shadow: 0 0 1px var(--primary);
   border: 1px solid var(--primary);
   color: var(--primary);
}

.form-floating label {
   color: gray;
}

/* Animation saludo */
/* ----------------------------------------------
  Generated by AnimatiSS
  Licensed under FreeBSD License
  URL: https://xsgames.co/animatiss
  Twitter: @xsgames_
---------------------------------------------- */

.scale-up-top-left-sp {
   animation: scale-up-top-left 0.9s;
   -webkit-animation: scale-up-top-left 0.9s;
}

@keyframes scale-up-top-left {
   0% {
      transform: scale(.5);
      transform-origin: top left;
      -webkit-transform: scale(.5);
      -moz-transform: scale(.5);
      -ms-transform: scale(.5);
      -o-transform: scale(.5);
   }

   100% {
      transform: scale(1);
      transform-origin: top left
   }
}

/* !New Login SP */

.img-idioma {
   height: auto;
   width: 10%;
}


@media only screen and (min-width: 400px) {
   .navbar-logo-icon {
      height: 46px;
   }
}


@media only screen and (min-width: 400px) {
   .navbar-logo-text {
      display: block;
   }
}

.navbar-menu {
   display: flex;
   transition: 0.3s;
}

@media only screen and (min-width: 600px) {
   .navbar-menu {
      flex: 1 1 auto;
      justify-content: center;
      margin-right: 120px;
   }
}

@media only screen and (max-width: 576px) {
   .navbar {
      padding: 0 1px;
   }
}

.navbar-menu>.button-nav-sp {
   position: relative;
   flex: 0 0 36px;
   display: grid;
   place-items: center;
   font-size: 28px;
   height: 72px;
   width: 36px;
   opacity: 0.3;
}

.navbar-menu>.button-nav-sp:hover {
   opacity: 1;
}


.navbar-menu>.button-nav-sp.active {
   opacity: 1;
}

.navbar-menu>.button-nav-sp.active::after {
   content: "";
   display: block;
   position: absolute;
   top: 18px;
   left: 50%;
   translate: -50% 0;
   width: 4px;
   height: 4px;
   border-radius: 50%;
   background: #36b7ee;
}

@media only screen and (min-width: 600px) {
   .navbar-menu>.button-nav-sp:last-child {
      position: absolute;
      right: 20px;
   }
}



.img-nav-sp {
   width: 50px;
   height: 50px;
}

.clear-nav-bar {
   backdrop-filter: saturate(180%) blur(5px) !important;
   background-color: rgba(255, 255, 255, 0.6) !important;
}

/* Login */
@keyframes rotate {
   100% {
      background-position: 0% 50%;
   }
}

.title-main-login {
   font-size: 25px;
}

.login-smart {
   min-height: 100vh;
   display: grid;
   place-items: center;
   background-repeat: no-repeat;
   background-size: cover;
   margin: 0;
   background-image: url("../src/components/img/backgrounds/bgsp00.svg");

   font-family: var(--fuente-main);
   color: #3a334e;
   /* animation: rotate 1s infinite alternate linear; */
}

button {
   background: transparent;
   border: 0;
   cursor: pointer;
}

.control {
   border: 1px solid #c0c0c0;
   border-radius: 8px;
   outline: none;
   width: 100%;
   height: 46px;
   padding: 0 16px;
   background: #ececec32;
   color: #c0c0c0;
   font-family: inherit;
   font-size: 16px;
   transition: 0.4s;
}

input {
   border: 1px solid #c0c0c0;
}

input:focus {
   color: var(--primario);
   border: 1px solid var(--primario);
}

.input-vending-filter {
   height: 50px;
}

.login-card {
   width: 400px;
   padding: 20px 30px 50px;
   border-radius: 1.25rem;
   background: #ffffff;
   text-align: center;
   -webkit-border-radius: 1.25rem;
   -moz-border-radius: 1.25rem;
   -ms-border-radius: 1.25rem;
   -o-border-radius: 1.25rem;
}

.login-card>h2 {
   font-size: 36px;
   font-weight: 600;
   margin: 0 0 6px;
}

.login-card>h3 {
   color: #837f90;
   margin: 5px 0 7px;
   font-weight: 500;
   font-size: 1rem;
}

.login-form {
   width: 100%;
   margin: 0;
   display: grid;
   gap: 16px;
}

.login-form input.control::placeholder {
   color: #aaa7bc;
}

button.control {
   margin-top: 10px;
   cursor: pointer;
   width: 100%;
   height: 46px;
   padding: 0 16px;
   background: var(--primario);
   color: #f9f9f9;
   border: 0;
   font-family: inherit;
   font-size: 1rem;
   font-weight: 600;
   text-align: center;
   letter-spacing: 2px;
   transition: all 0.375s;
}

button.control:hover {

   background: var(--primario-dark);
   color: #f9f9f9;
}

/* Users Business */
.login-wrapper {
   border: 1px solid #c0c0c0;
   height: 142px;
   width: 100%;
   overflow-y: scroll;
   margin: 5px 0;
   border-radius: 2px;
   -webkit-border-radius: 8px;
   -moz-border-radius: 2px;
   -ms-border-radius: 2px;
   -o-border-radius: 2px;
}

.password {
   position: relative;
}

.toggle {
   position: absolute;
   top: 50%;
   right: 16px;
   translate: 0 -50%;
   width: 30px;
   height: 30px;
   /* background-image: url("show.svg"); */
   background-size: 85%;
   background-position: center;
   background-repeat: no-repeat;
}

/* 
.toggle.showing {
  background-image: url("hide.svg");
} */

/* ?Sections  */
/* Header */
.focus-brand {
   animation: morph-effect 2.5s infinite ease-in-out;
   transform: translate(-50%, -50%);
   -webkit-transform: translate(-50%, -50%);
   -moz-transform: translate(-50%, -50%);
   -ms-transform: translate(-50%, -50%);
   -o-transform: translate(-50%, -50%);
   -webkit-animation: morph-effect 2.5s infinite ease-in-out;
}

.focus-brand:nth-child(1) {
   animation-delay: -3s;


}

.focus-brand:nth-child(2) {
   animation-delay: -2s;

}

@keyframes morph-effect {

   0%,
   20%,
   100% {
      filter: blur(0px);
      -webkit-filter: blur(0px);
      opacity: 1;
   }

   40%,
   80% {
      filter: blur(16px);
      -webkit-filter: blur(16px);
      opacity: 0;
   }
}


.section-container-app {
   overflow: auto;
   scroll-snap-type: y mandatory;

}

.section-area {
   scroll-snap-align: start;
}


/* Hero Section  */
.smart-pay-hero {

   background-image: url('./components/img/backgrounds/bg-main.png');
}


.bg-info {
   transform: scaleX(-1);
}


/* ? */
/* Slider hero  */
.card-item {
   border-radius: var(--border-2);
   width: 20px;
   height: 220px;
   background-color: var(--azul);
   -webkit-border-radius: var(--border-2);
   -moz-border-radius: var(--border-2);
   -ms-border-radius: var(--border-2);
   -o-border-radius: var(--border-2);
}

/* ?Order Views Section  */
/* Time Line Status Order   */
.timeline-with-icons {
   border-left: 1px solid hsl(0, 0%, 90%);
   position: relative;
   list-style: none;
}

.timeline-with-icons .timeline-item {
   position: relative;
}

.timeline-with-icons .timeline-item:after {
   position: absolute;
   display: block;
   top: 0;
}

.timeline-with-icons .timeline-icon {
   position: absolute;
   left: -48px;
   background-color: var(--primario);
   color: var(--blanco);
   border-radius: 50%;
   height: 31px;
   width: 31px;
   display: flex;
   align-items: center;
   justify-content: center;
}

/* Zig Zag Tikect  */

.zigzag {
   position: relative;
   max-width: 100%;
   width: auto;
   background: var(--gris);
}

.zigzag::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 30px;
   background: linear-gradient(135deg, #fff 12px,
         transparent 0%), linear-gradient(-135deg, #fff 12px,
         transparent 0%);
   background-size: 30px;
}

.zigzag::after {
   content: "";
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 30px;
   background: linear-gradient(45deg, #fff 12px,
         transparent 0%), linear-gradient(-45deg, #fff 12px,
         transparent 0%);
   background-size: 30px;
}


/* Extra */
.button-nav-sp {
   border: 0;
   padding: 0;
   font-family: inherit;
   background: transparent;
   color: inherit;
   cursor: pointer;
   text-decoration: none;
}



.custom-file-input {
   padding: 5px;
   color: rgb(150, 1, 1);
   /* border: 1px solid rgb(184, 184, 184); */
   vertical-align: middle;
}

.custom-file-input::-webkit-file-upload-button {
   visibility: hidden;
}

.custom-file-input::before {
   content: 'Seleccione una imagen ';
   color: #666;
   display: inline-block;
   background: #fff;
   border: 1px solid #999;
   border-radius: 8px;
   margin: -3px 0 -3px -3px;
   padding: 5px 20px;
   outline: none;
   white-space: nowrap;
   -webkit-user-select: none;
   cursor: pointer;
   text-align: center;
   text-shadow: 1px 1px #fff;
   font-weight: 700;
   font-size: 10pt;
   -webkit-border-radius: 8px;
   -moz-border-radius: 8px;
   -ms-border-radius: 8px;
   -o-border-radius: 8px;
}

.custom-file-input:hover::before {
   border-color: black;
}

.custom-file-input:active {
   outline: 0;
}

.custom-file-input:active::before {
   background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


/* Menu Main Dashboard  */
/* menu */
/* .main-menu {
  margin-top: 100px;
  height: 100%;
  width: 100%;
  padding: 0px 120px;
} */
/* main style responsive  */
.main-menu {
   max-width: 1100px;
   margin: 100px auto;
   padding: 0 10px;
}

.menu-banner {
   display: block;
   width: 100%;
   height: 360px;
   position: relative;
   margin-top: 0.5rem;
   /* padding: 1rem; */
   border-radius: 30px;
   cursor: pointer;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
}

.menu-banner>img {
   height: 100%;
   width: 100%;
   object-fit: cover;
   border-radius: 30px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
}

.menu-banner-promo {
   color: var(--blanco);
   position: absolute;
   top: 47%;
   left: 13%;
   font-weight: 800;

}

.menu-banner-promo>h2>span {
   color: var(--rojo);
   font-size: 50px;

}

.menu-title {
   /* padding: 0 1rem; */
   margin-top: 30px;
}

.menu-options {
   color: #000;
   display: flex;
   gap: 10px;
   padding: 0.3rem auto;
   overflow-x: auto;

}

.menu-options>a {
   padding: 0.5rem 20px;
   font-size: 0.9rem;
   color: var(--primario);
   background-color: var(--primario-light);
   font-weight: 600;
   text-decoration: none;
   text-align: center;
   border-radius: 20px;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   -ms-border-radius: 20px;
   -o-border-radius: 20px;
}

.menu-options>a:hover {
   color: var(--blanco);
   background-color: var(--primario);
}

.menu-content {
   margin: 1rem 0;
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
   gap: 20px;
   padding: 0.5rem 0rem 1rem 0rem;

}

.menu-card {
   cursor: pointer;
   color: #000;
   position: static;
   border-radius: 15px;
   -webkit-border-radius: 15px;
   -moz-border-radius: 15px;
   -ms-border-radius: 15px;
   -o-border-radius: 15px;
}

/* .menu-card:hover {
  cursor: pointer;
  color: #000;
  position: relative;
  border: var(--primario-light) solid 1px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
} */

.card-image {
   width: 100%;
   height: 150px;
   object-fit: cover;
   border-radius: 15px;
   -webkit-border-radius: 15px;
   -moz-border-radius: 15px;
   -ms-border-radius: 15px;
   -o-border-radius: 15px;
}

.card-detail {
   padding: 2px 5px;
}

.card-detail>h4 {
   font-size: 19px;
   color: var(--primario);
}

.card-detail>h4>span {
   font-size: 20px;
   color: var(--negro);
   float: right;
}

.card-time>span {
   float: bottom;
}

/* Payment Request  */
.payment-main {
   font-family: var(--fuente-main);
}

.payment-smart {
   display: grid;
   place-items: center;
   margin: 0;
   background-repeat: no-repeat;
   background-size: cover;
   font-family: var(--fuente-main);
}

.smart-card {
   width: 380px;
   padding: 20px 30px 30px;
   border-radius: 1.25rem;
   background: #ffffff;
   text-align: center;
   -webkit-border-radius: 1.25rem;
   -moz-border-radius: 1.25rem;
   -ms-border-radius: 1.25rem;
   -o-border-radius: 1.25rem;
}

.payment-sp {
   margin-top: 5rem;
}

label {
   color: var(--gris-2);
}

.icon-invoice {
   height: 32px;
   width: 32px;
}

.cover-container {
   margin-top: 120px;
   max-width: 42em;
   background-image: url('./components/img/backgrounds/regis2.png');
   place-items: center;
   background-repeat: no-repeat;
   background-size: cover;
   object-fit: cover;
}

/* !Revisar clases de esta area */
.bg-gray-light {
   background-color: #ededed4a;
}

.border-history {
   border-radius: 1.25rem;
}

.biller-card {
   width: 380px;
   padding: 20px 30px 30px;
   border-radius: 1.25rem;
   background: #ffffff;
   text-align: center;
   -webkit-border-radius: 1.25rem;
   -moz-border-radius: 1.25rem;
   -ms-border-radius: 1.25rem;
   -o-border-radius: 1.25rem;

}

.opacity-50 {
   opacity: .5;
}

.opacity-75 {
   opacity: .75;
}

.list-group {
   width: auto;
   max-width: 460px;

}

.form-check-input:checked+.form-checked-content {
   opacity: .5;
}

.form-check-input-placeholder {
   pointer-events: none;
   border-style: dashed;
}

[contenteditable]:focus {
   outline: 0;
}

.list-group-checkable {
   display: grid;
   gap: .5rem;
   border: 0;
}

.list-group-checkable .list-group-item {
   cursor: pointer;
   border-radius: .5rem;
}

.list-group-item-check {
   position: absolute;
   clip: rect(0, 0, 0, 0);
   pointer-events: none;

}


.list-group-item-check:hover+.list-group-item {
   background-color: var(--bs-light);
   border: 1px solid #007bff;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}

.list-group-item-check:checked+.list-group-item {
   color: #007bff;
   background-color: var(--bs-light);
   border: 1px solid #007bff;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}


.list-group-item-check[disabled]+.list-group-item,
.list-group-item-check:disabled+.list-group-item {
   pointer-events: none;
   filter: none;
   opacity: .5;
}


/* Search Input  */
.us-search .form-control {
   padding-left: 2.375rem;
}

.us-search .form-control-feedback {
   top: 300px;
   transform: scale(1.4);
   position: absolute;
   z-index: 2;
   /* display: block; */
   /* width: 2.375rem;
  height: 0.375rem; */
   line-height: 2.375rem;
   /* text-align: center; */
   pointer-events: none;
   color: #aaa;
   /* -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4); */
}

/* Ecomerce */
.order-wrapper {
   border: 1px solid var(--gris-3);
   height: 260px;
   width: 100%;
   overflow-y: scroll;
   margin: 20px 0;
   border-radius: var(--border-2);
}

.order-card {
   display: grid;
   grid-template-columns: 0.6fr 1fr 0.5fr;
   padding: 10px 0;
   margin-left: 15px;
   gap: 2px;
   justify-content: space-around;
   align-items: flex-start;
}

.order-image {
   width: 90px;
   height: 90px;
   object-fit: cover;
   border-radius: var(--border-2);
   -webkit-border-radius: var(--border-2);
   -moz-border-radius: var(--border-2);
   -ms-border-radius: var(--border-2);
   -o-border-radius: var(--border-2);
}


.order-detail>input {
   width: 50px;
   height: 30px;
   text-align: center;
   border: 1px solid var(--gris-3);
   border-radius: var(--border-2);
   -webkit-border-radius: var(--border-2);
   -moz-border-radius: var(--border-2);
   -ms-border-radius: var(--border-2);
   -o-border-radius: var(--border-2);
   background-color: transparent;
   margin-left: 2px;
}

.order-detail>p {
   width: 150px;
   margin-right: 5px;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}

.order-total>p>span {
   float: right;
}

.text-size-order {
   font-size: 20px;
}

.button-promo {
   background-color: var(--primario);

}


.description-product {
   width: 150px;
   margin-right: 5px;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}


.card-image-modal {
   width: auto;
   height: 230px;
   border-radius: var(--border-2);
   -webkit-border-radius: var(--border-2);
   -moz-border-radius: var(--border-2);
   -ms-border-radius: var(--border-2);
   -o-border-radius: var(--border-2);
}

/* Revisar */

.categories-sp {
   cursor: pointer;
}


.card-title {
   color: var(--primario);
   font-size: 15px;

   word-wrap: break-word;
}

@media (max-width: 575.98px)and (orientation:portrait) {

   .description-product {
      width: 100px;
      margin-right: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
   }

   .menu-banner>img {
      height: 132%;
      width: 100%;
      object-fit: cover;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
   }

   .menu-banner {
      display: block;
      width: 100%;
      height: 92px;
      position: relative;
      margin-top: 0.5rem;
      /* padding: 1rem; */
      border-radius: 30px;
      cursor: pointer;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
   }

   .menu-title {
      margin-top: 56px;
   }
}



/* Transassciont orders  */
.transaction-wrapper {
   border: 1px solid var(--gris);
   height: 472px;
   width: 100%;
   overflow-y: scroll;
   margin: 20px 0;
   border-radius: var(--border-3);
   -webkit-border-radius: var(--border-3);
   -moz-border-radius: var(--border-3);
   -ms-border-radius: var(--border-3);
   -o-border-radius: var(--border-3);
}

.transaction-wrapper-2 {
   border: 1px solid var(--gris);
   height: 395px;
   width: 100%;
   overflow-y: scroll;
   margin: 20px 0;
   border-radius: var(--border-3);
   -webkit-border-radius: var(--border-3);
   -moz-border-radius: var(--border-3);
   -ms-border-radius: var(--border-3);
   -o-border-radius: var(--border-3);
}

.transaction-wrapper-3 {
   border: 1px solid var(--gris);
   height: 650px;
   width: 100%;
   overflow-y: scroll;
   margin: 20px 0;
   border-radius: var(--border-3);
   -webkit-border-radius: var(--border-3);
   -moz-border-radius: var(--border-3);
   -ms-border-radius: var(--border-3);
   -o-border-radius: var(--border-3);
}


.transaction-wrapper-4 {
   border: 1px solid var(--gris);
   width: 100%;
   margin: 20px 0;
   border-radius: var(--border-3);
   -webkit-border-radius: var(--border-3);
   -moz-border-radius: var(--border-3);
   -ms-border-radius: var(--border-3);
   -o-border-radius: var(--border-3);
}

.sumamary-text {
   font-size: 15px;
   font-weight: bold;
   color: #0a89fe;
}

.button-payment {
   font-size: 20px;
   width: 95%;
}

.img-card-max-size {
   max-width: 90%;
}

.list-group {
   width: auto;
   max-width: 460px;
}

.container-smart-pay {
   max-width: 99rem;
   margin: 0 auto;
   padding: 0 1rem;
}

.btn-upadate:hover {
   color: var(--blanco);
   background-color: var(--azul);
}

.btn-close-box:hover {
   color: var(--blanco);
   background-color: var(--rojo);
}

.rotate {
   animation: rotation 45s infinite linear;
   -webkit-animation: rotation 45s infinite linear;
}

@keyframes rotation {
   from {
      transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      -moz-transform: rotate(359deg);
      -ms-transform: rotate(359deg);
      -o-transform: rotate(359deg);
   }

   to {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
   }
}


.tooltip {
   text-decoration: none !important;
}

.tooltip::after {
   font-size: 13px;
   background-color: var(--negro);
   border-radius: 10px;
   color: var(--blanco);
   display: none;
   width: 100px;
   padding: 5px 3px;
   position: absolute;
   text-align: center;
   z-index: 999;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

.tooltip::before {
   background-color: var(--negro);
   content: ' ';
   display: none;
   position: absolute;
   width: 15px;
   height: 15px;
   z-index: 999;
}

.tooltip:hover::after {
   display: block;
}

.tooltip:hover::before {
   display: block;
}

.tooltip.top::after {
   content: 'top';
   top: 0;
   left: 50%;
   transform: translate(-50%, calc(-100% - 10px));
}

.tooltip.top::before {
   top: 0;
   left: 50%;
   transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tooltip.bottom::after {
   content: 'bottom';
   bottom: 0;
   left: 50%;
   transform: translate(-50%, calc(100% + 10px));
}

.tooltip.bottom::before {
   bottom: 0;
   left: 50%;
   transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
}

.tooltip.right::after {
   content: 'right';
   top: 0;
   right: 0;
   transform: translateX(calc(100% + 10px));
}

.tooltip.right::before {
   top: 50%;
   right: 0;
   transform: translate(calc(100% + 5px), -50%) rotate(45deg);
}

.tooltip.left::after {
   content: 'Cerrar Sesión';
   top: 9;
   left: 0;
   transform: translateX(calc(-100% - 10px));
}

.tooltip.left::before {
   top: 50%;
   left: 0;
   transform: translate(calc(-100% - 5px), -50%) rotate(45deg);
}


.bussines-donlee {
   color: #FA4616;
   font-size: 30px;
}

th {
   color: #212529;
}

.board img {
   width: 80px;
   height: 80px;
   object-fit: cover;
   border-radius: 50%;

}

/* Butonss dollar */
.button-dollar .button-coin {
   cursor: pointer;
}

.button-dollar::before {
   cursor: pointer;
}

.button-dollar:active {
   transform: scale(0.90);
   box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
   -webkit-transform: scale(0.90);
   -moz-transform: scale(0.90);
   -ms-transform: scale(0.90);
   -o-transform: scale(0.90);
}

.button-coin:active {
   width: 4.5rem;
   height: 4.5rem;
   border-radius: 50%;
   background: rgb(102, 53, 53);
   box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   transform: scale(0.90);
   -webkit-transform: scale(0.90);
   -moz-transform: scale(0.90);
   -ms-transform: scale(0.90);
   -o-transform: scale(0.90);
}


.button-dollar:hover {
   cursor: pointer;
   border: 1px solid #d0d0d0;
}


.dollar-img {
   object-fit: cover;
   width: 100%;
   height: 100%;
}

@media screen and (min-width: 1080px) and (min-height: 1920px) {


   .dollar-img {
      object-fit: cover;
      width: 100%;
      height: 200px;
   }

   .list-discount-framer-2 {
      height: 640px;
   }



   .coin-img {
      object-fit: cover;
      width: 120px;
      height: 100px;
   }

   /* .coin {
      width: 12rem;
      height: 1rem;
      border-radius: 50%;
      background: rgb(102, 53, 53);
      box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
   } */



}



   .coin-img {
      object-fit: cover;
      width: 120px;
      height: 120px;
   }


.coin {

   /* border-radius: 50%;
   background: rgb(102, 53, 53);
   box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); */
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
}

.coin>div {
   font-family: sans-serif;
   color: white;
   font-size: 1rem;
   cursor: pointer;
}


.container-content {
   position: relative;
   min-height: 480px;
   width: 500px;
   margin: 10px auto;

}

/*-----:::::main Post Menu:::::-----*/
.main-post {
   margin-top: 0px;
}

.number-counter {
   display: flex;
   align-items: center;
   justify-content: center;
}

.number-counter p {
   margin: 0 1rem;
}

.number-counter button {
   text-align: center;
   font-weight: 700;
   font-size: 25px;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   border: none;
   background-color: #000000;
   color: #fff;
   cursor: pointer;
}

.number-counter button:hover {
   background-color: #555;
}

.counter-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.round-button {
   width: 30px;
   height: 30px;
   border-radius: 50%;
   background-color: #3498db;
   font-weight: 700;
   color: #fff;
   font-size: 20px;
   text-align: center;
   line-height: 30px;
   cursor: pointer;
}

.round-count {
   width: 30px;
   height: 30px;
   border-radius: 50%;
   font-weight: 700;
   color: #3498db;
   /* background-color: var(--gris); */
   font-size: 25px;
   text-align: center;
   line-height: 30px;
   cursor: pointer;

}

.round-count:active {
   transform: scale(.85);
   -webkit-transform: scale(.85);
   -moz-transform: scale(.85);
   -ms-transform: scale(.85);
   -o-transform: scale(.85);
}

.title-product {
   width: 150px;
   white-space: nowrap;
   /* Evita que el texto se divida en varias líneas */
   overflow: hidden;
   /* Oculta el texto que no cabe en el contenedor */
   text-overflow: ellipsis;
   /* Agrega puntos suspensivos al final del texto recortado */
}


.image-options input[type="radio"] {
   display: none;
   /* Oculta los botones de opción */
}

.image-options label {
   display: inline-block;
   cursor: pointer;
   padding: 5px;
   border: 1px solid #ccc;
}

.image-options label:hover {
   border-color: #555;
}

/* Estilos para el botón de opción seleccionado */
.image-options input[type="radio"]:checked+label {
   border-color: #007bff;
}

/* Estilos para las imágenes dentro de los botones de opción */
.image-options img {
   max-width: 100%;
   height: auto;
}

/* Met PAYMENTS */
.img-pay-met {
   width: 70px;
   height: 70px;
   object-fit: contain;
}

/* lIST CATEGORIES  */
.container-list {
   padding: 2px 0px;
   overflow: scroll;
   overflow-x: auto;
   white-space: nowrap;
   position: relative;
}

.container-list::-webkit-scrollbar {
   width: 2px !important;
}

.container-list ul {
   list-style: none;
   margin: 0px;
   padding: 0;
}

.container-list li {
   display: inline-block;
   margin: 1px 0px;
}

.container-list label {
   width: auto;
}

.button-container {
   background-color: #fff;
   display: inline-block;
   vertical-align: top;
   margin-right: 5px;
}

.button-container img {
   width: 30px;
   height: 30px;
   margin-right: 5px;
}

.button-container-img-categories-vending {
   width: 100px !important;
   height: 100px !important;
   margin-right: 5px !important;
}

.button-container label {
   display: inline-block;
   vertical-align: top;
   white-space: nowrap;
}

.title-card-product {
   font-size: 14px;
}

/*Not Bar Scroll */
.no-scroll::-webkit-scrollbar {
   display: none;
}

/* Sections Sopping & Menu  */

.section-menu-board {
   width: 0;
   padding-bottom: 150px;
   /* height: 100vh;
  flex-basis: 100%;
  width: 65%; */
}

.grid-pos {
   flex: 1;
   /* margin-top: 60px; */
   grid-template-columns: 1fr 2fr;
}

.section-shopping-cart {
   height: 100vh;
   background-color: #22a929;
}




.btn-pos-pay {
   background-color: #1ca323;
   color: #fff;
}

@media (max-width: 991px) {
   /* Sections menu */

   /* .section-shopping-cart {
    height: 100vh;
    flex-basis: 26.7%;
    width: 100%;
  } */

   /* 
  .section-menu-board {
    height: 100vh;
    flex-basis: 100%;
    width: 100%;
  } */
}

/* .suggestions-over{  
} */
.suggestions-wrapper {
   height: 450px;
   width: 100%;
   overflow-y: scroll;
   margin: 0px 0;
   position: relative;
}

.product-table-menu {
   /* margin-top: 70px; */
   /* width: 100% */
   /* margin-bottom: 0;
  width: 64.4%;
  overflow-y: scroll;
  position: absolute;
  top: 10px;
  right: 0; */

}

.title-product-pos {
   font-size: 17px;
   font-weight: 800;
   text-align: center;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   width: 100%;
}

.header-shopping-cart {
   margin: 0;
}

.shopping-wrapper {
   width: 100%;
   height: 100%;
   overflow-x: scroll;
   overflow-y: scroll;
   position: relative;
}

/* .totals-section {
  border: 1px solid var(--bg-primario);
  left: 0;
  bottom: 0;
} */







/* @media only screen and (min-width: 600px) {
  .position-fixed-sp{
    position: fixed;
    top: 3;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 1;
  
  }
  } */

::-webkit-scrollbar {
   height: 9px;
   width: 5px;
}

::-webkit-scrollbar-thumb {
   background-color: #ccc;
   border-radius: 6px;
}

::-webkit-scrollbar-track {
   background-color: #f1f1f1;
   border-radius: 6px;
}

.card-product-sp {
   cursor: pointer;
}

.card-img-top-sp {
   background-color: #c5c5c533;
   width: 100%;
   height: 130px;
   object-fit: cover;
}

/* :::CSS DE EJEMPLO ::: */
.side-bar {
   background-color: #1ca323;
   width: 33%;
   height: 100vh;
}

.c-1 {
   background-color: #007bff;

}

.c-2 {
   background-color: #FA4616;
}


/* :::END CSS DE EJEMPLO ::: */

/* Product Menu Cards */
.card-product-menu {
   background-color: var(--blanco);
   border: 1px #000 solid;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
   max-width: 300px;
   margin: auto;
   text-align: center;
   font-family: arial;
}

.card-product-name {
   color: var(--gris-2);
   text-align: center;
}

.card-product-price {
   color: var(--primario);
   text-align: center;
   font-size: 15px;
}

.total-shopping {
   border-top: 3px dashed #bad5d86c;
   padding: 2px 0;
}

.btn-check {
   background-color: var(--gris-2);
}

.title-product-cart-xl {
   font-size: 26px;
   font-weight: 700;
   width: 400px;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}


.text-price-cart {
   font-size: 18px;
   font-weight: 800;
   color: var(--primario);
}

.text-price-cart-xl {
   font-size: 30px;
   font-weight: 800;
   color: var(--primario);
}

.icon-delete {
   font-size: 25px;
}

.count-items {
   font-size: 25px;
}

@media (width <=1120px) {

   .title-product-cart-xl {
      font-size: 20px;
      font-weight: 700;
      width: 300px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
   }

   .text-price-cart-xl {
      display: inline-block;
      margin: 0px 10px;
      font-size: 25px;
      font-weight: 800;
      color: var(--primario)
   }

}


@media (width <=933px) {

   .title-product-cart-xl {
      font-size: 20px;
      font-weight: 700;
      width: 160px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
   }

   .text-price-cart-xl {
      display: inline-block;
      margin: 0px 5px;
      font-size: 20px;
      font-weight: 800;
      color: var(--primario)
   }
}


/* Ocultar el icono predeterminado */
details summary::-webkit-details-marker,
details summary::marker {
   display: none;
   content: "";
}

/* Agregar un nuevo icono */
summary:before {
   content: "";
   /* Aquí se puede cambiar el contenido para el nuevo icono */
   display: inline-block;
   font-size: 18px;
   font-weight: 800;
   margin-right: 0;
   transform: rotate(0deg);
   transition: transform 0.2s ease-in-out;
}

/* Cambiar el icono cuando el contenido está abierto */
details[open] summary:before {
   color: var(--azul);
   transform: rotate(45deg);
}

/*::::::::::Vending Styles::::::::::  */

/* buttons  */
.btn-vending {
   color: var(--blanco);
   background-color: var(--primary-vending);
}

/* buttons  */
.btn-vending-pay {
   font-size: 5rem;
}

/* QR Img */
.vending-content {
   height: 100vh;
   background-color: #007bff;
}

.vendindg-title {
   margin: 40px 0;
}

.vendindg-img {
   margin: 0px 0 auto 0;
}

.vending-qr {
   width: 200px;
   height: 200px;
   object-fit: fill;

   border-radius: 40px;
   -webkit-border-radius: 40px;
   -moz-border-radius: 40px;
   -ms-border-radius: 40px;
   -o-border-radius: 40px;
}

.vending-button {
   margin: 80px 0;
}

/* keyPad Numeric  */
.keypad-input {
   color: var(--primary-vending);
   font-size: 25px;
   font-weight: 700;
   text-align: center;
   border: 2px solid rgb(226, 226, 226);
   letter-spacing: 20px;
}

.btn-continue {
   width: 100%;
   color: #ffffff;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
   background-color: #22a929;
   padding: 30px 10px;
   font-weight: 500;
   margin-bottom: 15px;
}

.btn-continue:hover {
   width: 100%;
   color: #ffffff;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
   background-color: #26b52dcd;
   font-weight: 500;
   margin-bottom: 15px;
}


.section-keyboard {
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   border-radius: 30px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
   padding: 10px;
}

.keyboard-pad>.num,
.delete {
   background-color: #fff;
   color: #000;
   font-weight: 800;
   text-align: center;
   font-size: 60px;
   border-radius: 10px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
   padding: 35px 0px;
   width: calc(33.333% - 8px);
   margin: 0 4px 8px;
   cursor: pointer;
   transition: all 0.2s linear;
   -webkit-transition: all 0.2s linear;
   -moz-transition: all 0.2s linear;
   -ms-transition: all 0.2s linear;
   -o-transition: all 0.2s linear;
   border: 2px solid rgb(3, 53, 218);
   /*box-shadow: 2px 2px 0 rgb(0, 0, 0, .2);
  */
   inset: 4px -4px 0px #d4c69d;
   inset: -4px 4px 0px #beb28d;
   border-radius: 30px;
}

.keyboard-pad>.keyboardKey {
   color: #000;
   font-weight: 800;
   text-align: center;
   font-size: 2rem;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
   padding: 45px 0px;
   width: calc(12.333% - 8px);
   margin: 0 4px 8px;
   cursor: pointer;
   transition: all 0.2s linear;
   -webkit-transition: all 0.2s linear;
   -moz-transition: all 0.2s linear;
   -ms-transition: all 0.2s linear;
   -o-transition: all 0.2s linear;
   border: 2px solid rgb(226, 226, 226);
   /* box-shadow: 2px 2px 0 rgb(0, 0, 0, .2); */
   inset: 4px -4px 0px #d4c69d;
   inset: -4px 4px 0px #beb28d;
}

.keyboard-pad>.delete {
   color: #000;
   font-weight: 800;
   text-align: center;
   font-size: 2rem;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
   padding: 45px 0px;
   width: calc(20.333% - 8px);
   margin: 0 4px 8px;
   cursor: pointer;
   transition: all 0.2s linear;
   -webkit-transition: all 0.2s linear;
   -moz-transition: all 0.2s linear;
   -ms-transition: all 0.2s linear;
   -o-transition: all 0.2s linear;
   border: 2px solid rgb(226, 226, 226);
   /* box-shadow: 2px 2px 0 rgb(0, 0, 0, .2); */
   inset: 4px -4px 0px #d4c69d;
   inset: -4px 4px 0px #beb28d;
}


.num:active {
   box-shadow: 3px 3px 2px rgb(0, 0, 0, .25);
   inset: 3px -3px 0px #d4c69d;
   inset: -3px 3px 0px #beb28d;
   transform: translate(3px, 3px);
   -webkit-transform: translate(3px, 3px);
   -moz-transform: translate(3px, 3px);
   -ms-transform: translate(3px, 3px);
   -o-transform: translate(3px, 3px);
   transition: 0.2s ease;
   -webkit-transition: 0.2s ease;
   -moz-transition: 0.2s ease;
   -ms-transition: 0.2s ease;
   -o-transition: 0.2s ease;
}

.delete:active {
   box-shadow: 3px 3px 2px rgb(0, 0, 0, .25);
   inset: 3px -3px 0px #d49d9d;
   inset: -3px 3px 0px #be938d;
   transform: scale(-1px, -1px);
   -webkit-transform: scale(-1px, -1px);
   -moz-transform: scale(-1px, -1px);
   -ms-transform: scale(-1px, -1px);
   -o-transform: scale(-1px, -1px);
   transition: 0.2s ease;
   -webkit-transition: 0.2s ease;
   -moz-transition: 0.2s ease;
   -ms-transition: 0.2s ease;
   -o-transition: 0.2s ease;
}

@media (max-width: 575.98px)and (orientation:portrait) {

   .container-content {
      width: 390px;
      margin: 10px auto;
   }

   .keyboard-pad>.num,
   .delete {
      color: #000;
      font-weight: 700;
      font-size: 24px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      padding: 40px 0;
      width: calc(33.333% - 8px);
      margin: 0 4px 8px;
      text-align: center;
      background-color: rgba(241, 241, 241, 0.8);
      cursor: pointer;
      transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      border: 1px solid rgb(226, 226, 226);
      box-shadow: 2px 2px 0 rgb(0, 0, 0, .2);
      inset: 4px -4px 0px #d4c69d;
      inset: -4px 4px 0px #beb28d;
   }

   .section-keyboard {
      top: 0;
      left: 0;
      width: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      padding: 7px;
   }
}


.bg-pay-met {
   background-position: center;
   background-repeat: no-repeat;
   object-fit: cover;
   background-image: url(./components/PaymentPost/img/bg-bb-2.svg);
}

/* @media screen and (min-width: 1080px) 
and (max-width: 1920px) 
and (min-height: 1920px) 
and (max-height: 1080px) { */

.main-pos-smartpay {
   background-position: center;
   background-repeat: repeat;
   object-fit: fill;
   background-image: url(./components/PaymentPost/img/bg-bb-2.svg);
}

/* Pre Account  */

.logo-main {
   width: 50px;
   height: 50px;
}


.img-product-pa {
   width: 200px;
   height: 170px;
   object-fit: contain;
}

.title-product-pa {
   font-size: 23px;
   font-weight: 500;
}

.add-product {
   background-color: var(--verde-2);
}

/* Menu Drop Down  */
.menu-dropdown-sp {

   z-index: 1399;
   background-color: var(--blanco);
   ;
}

.menu-dropdown-sp ul>li {
   display: flex;
   flex-direction: column;
   align-items: start;
   list-style: none;
}

.menu-dropdown-sp ul>li:hover {
   background-color: var(--gris);
}

/*======= Styles of PreAccount ========  */
.container-pre-account {
   height: 100vh;
   /* border: 3px solid #0025B8; */
}

/* .container-main-pre-account {
  position: relative; 
  align-items: start;
 
} */
/* .grid-main-preaccount {
  display: grid;
  position: relative;
  grid-template-columns: 100%;

} */


.dropdown-index {
   z-index: 1000;
}

.header-main-pre {
   position: relative;
   /* Asegura que el contenedor padre sea la referencia de posición */
   width: 500px;
   /* Ancho deseado del contenedor padre */
}

.header-preaccount {
   position: fixed;
   height: 40px;
   top: 0;
   z-index: 1055;
   width: 100%;
}

.pre-example {
   position: fixed;
   top: 40px;
   z-index: 1055;
   width: 1290px;
   background-color: #fff;
}

.navigation-preaccount {
   height: 50px;
   top: 40px;
   /* position: fixed; */
   /* z-index: 1055; */
}

.aside-preaccount {
   height: 50px;
   background-color: #0025B8;
   top: 80px;
}

.main-preaccount {
   width: 100%;
   overflow-y: scroll;
   top: 41px;

}

.menu-pre {
   margin-bottom: 200px;
}

.total-pre {
   font-size: 20px;
   margin: 0 20px;
}

.footer-preaccount {
   grid-area: footer;
}



.buttons-accions {
   width: 100%;
   position: fixed;
   bottom: 0;
   background-color: transparent;
   z-index: 1055;
   margin-bottom: 2px;
}

.buttons-accions-grid {
   /* display: grid; */
   grid-template-columns: 170px 2fr;
   display: flex;
   justify-content: space-between;
}


.section-shopping-button {
   width: 200px;
   margin: 0;
}

.section-acction {
   z-index: 1075;
}



.floating-button {
   /*position: fixed;
  bottom: 5px;
  left: 5px;
  */
   display: block;
   background-color: #0048ff;
   color: #ffffff;
   padding: 5px 0px;
   text-decoration: none;
   z-index: 1055;
   border-radius: 30px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
}

.floating-button:hover {
   background-color: #0029cc;
}

.container-button {
   /* position: relative; */

   /* z-index: 1055; */
}

.floating-button-right {
   position: absolute;
   bottom: 0;
   right: 0;
   display: inline-block;
   /* background-color: #414141cc; */
   color: #ffffff;
   padding: 15px 0px;
   border-radius: 40px;
   text-decoration: none;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
   -webkit-border-radius: 40px;
   -moz-border-radius: 40px;
   -ms-border-radius: 40px;
   -o-border-radius: 40px;
}

.buttons-accions-pa>i {
   font-size: 25px;
   padding: auto;
}


.categories-pre-account {
   display: inline-block;

}


/*======= End Styles of PreAccount ========  */

.container-categories-pre {
   position: fixed;
   top: 40px;
   left: 0;
   width: 100%;
   background-color: rgb(236, 242, 236);
   /* background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37); */
   border: 1px solid rgba(255, 255, 255, 0.18);
   padding: 10px;
   z-index: 1054;

}

.content-main-pre-account {
   margin-top: 120px;
   margin-bottom: 110px;
}

.container-categories-list-pre {
   overflow-x: auto;
   white-space: nowrap;
}


.lista-botones {
   list-style: none;
   margin: 0;
   padding: 0;
   display: inline-block;
}

.lista-botones li {
   display: inline;
   margin-right: 10px;
}

.lista-botones li:last-child {
   margin-right: 0;
}

.section-acction {
   overflow-x: auto;
   max-height: 100%;
}


/* lIST of buttons categories   */
.container-list-buttons {
   padding: 7px 0px 7px 10px;
   overflow: scroll;
   overflow-x: auto;
   white-space: nowrap;
   background-color: #00000069;
   border-radius: 30px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
}

.container-list-buttons::-webkit-scrollbar {
   width: 2px !important;
}

.container-list-buttons ul {
   list-style: none;
   margin: 0;
   padding: 0;
}

.container-list-buttons li {
   display: inline-block;
   margin-right: 10px;
}

.container-list-buttons label {
   width: auto;
}

/* Sections Sopping & Menu  */
.section-shopping-cart-pre {
   flex-basis: 35%;
   width: 100%;

}


/* Ctaegories del post  */
.categories-list-post {
   margin: 0;
   /* z-index: 1040;
  position: fixed;
  width: 64%;
  top: 0;
  right: 0; */
}

.pila-shopping-cart {
   width: 100%;
   height: 60%;
   overflow-x: scroll;
   overflow-y: scroll;
   position: relative;
}

.card-img-pre-acoount {
   width: 100%;
   height: 200px;
   object-fit: cover;

}


.border-product:hover {
   border: 3px solid #e3e3e372;
   border-radius: 20px;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   -ms-border-radius: 20px;
   -o-border-radius: 20px;
}


@media screen and (min-width: 1080px) and (min-height: 1920px) {

   .content-main-pre-account {
      margin-top: 190px;
      margin-bottom: 110px;
   }

   .pila-shopping-cart {
      width: 100%;
      height: 80%;
      overflow-x: scroll;
      overflow-y: scroll;
      position: relative;
   }

   .btn-prueba {
      width: 20rem;
   }

   .card-title {
      font: 1em sans-serif;
      color: #000;
   }

}

.menu-pos {
   width: 100%;
   height: 100vh;
}

.flex-container-pos>div {
   border-radius: 5px;
   padding: 2px 3px;
}

/* PreAccount */

.pre-account-login {
   color: #fff;
   background-image: linear-gradient(178.5deg, rgba(2, 36, 184, 1) 2.7%, rgba(100, 129, 255, 1) 98%);
}

.form-signin {
   width: 100%;
   max-width: 330px;
   padding: 15px;
   margin: auto;
}

.form-signin .checkbox {
   font-weight: 400;
}

.form-signin .form-floating:focus-within {
   z-index: 2;
}

.form-signin input[type="email"] {
   margin-bottom: -1px;
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
   margin-bottom: 10px;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
}

.form-signin button {
   background-color: #0025B8;
}


.list-tables .form-signin {
   background-color: #0025B8;
}

.form-signinbutton:focus {
   background-color: #0025B8;
}

.form-signin button:active {
   background-color: #0025B8;
}

/* .form-signin button:disabled {  */


button:not(:disabled):not(.disabled) {
   cursor: pointer;
}

/* .form-signin button:not(:disabled):not(.disabled):active {
  background-color:
}.form-signin button:not(:disabled):not(.disabled):focus{background-color: #0025B8;
}.form-signinbutton:not(:disabled):not(.disabled):hover{
   
} */

.form-signin button:not(:disabled):not(.disabled):active {
   background-color: #0025B8;
}

-user {
   list-style: none;
}

.title-product-table {
   font-size: 17px;
   font-weight: 800;
   text-align: center;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   width: 80%;
}

.mobile-bar {
   display: none;
}

@media (max-width: 800px) {

   .fixed-top-sp {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030;
   }

   .container-hidden-mobile {
      visibility: hidden;
      display: none;
   }

   .mobile-bar {
      display: block;
   }

}

/*--------------------------------------------Translate-------------------*/
.skiptranslate {
   display: none !important;
}

.bodyposition {
   position: static !important;
}


/* Menu */

/* nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 60px;
  padding: 0 10px;
  background: #161b22;
} */

.burger {
   display: none;
   cursor: pointer;
}

/* .menu-items,
.menu {
  display: flex;
  gap: 16px;
}

.menu-right {
  position: relative;
  margin-left: auto;
  gap: 6px;
}

.menu-right img {
  width: 30px;
  height: 30px;
  padding: 5px;
}

.menu-right .badge {
  position: absolute;
  top: 0;
  left: 14px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #161b22;
  background: linear-gradient(#54a3ff, #006eed);
}

a,
a:is(:visited, :active) {
  color: #ffffff;
  text-decoration: none;
  line-height: 24px;
}

.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #0d1117;
  border: 1px solid #30363d;
  border-radius: 6px;
  padding-right: 4px;
}

.search input {
  padding: 4px 10px;
  color: #c9d1d9;
  font-size: 13px;
  font-family: inherit;
  border: 0;
  background: transparent;
}

.search button {
  border: inherit;
  border-radius: inherit;
  background: transparent;
  color: #8b949e;
} */

@media (width <=950px) {
   /* .menu-right img:nth-child(n + 3) {
    display: none;
  }

  .menu-items {
    display: none;
    width: 100%;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    top: 60px;
    left: 0;
    padding: 0 20px 20px;
    background: #161b22;
  }

  .menu-left {
    flex-direction: column;
    gap: 12px;
  }

  .menu-left a {
    border-bottom: 1px solid #30363d;
    padding-bottom: 10px;
  }

  .menu-right {
    margin-left: 0;
  } */

   .burger {
      display: block;
   }

   .menu-label,
   .search-bar,
   .hidden-text {
      display: none;
   }

   .label-responsive {
      display: block;
   }



   .total-shopping {
      border-top: 6px dashed #bad5d86c;
      padding: 2px 0;
   }

   .user-name,
   .buttons-label {
      font-size: 17px;
   }

   .buttons-label {
      font-size: 15px;

   }


   body {
      background-color: #0025b800;
   }
}

@media (width <=420px) {

   .icon-mobile {
      font-size: 30px;
   }

   .hidden-label-sm {
      font-weight: 800;
      display: block;
      font-size: 11px;
   }


}

/* button {
  background: #FBCA1F;
  font-family: inherit;
  padding: 0.6em 1.3em;
  font-weight: 900;
  font-size: 18px;
  border: 3px solid var(--new-frame);
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
}    */


.card-pos {
   border: 2px solid var(--new-frame);
}

.border-pos {
   border: 2px solid var(--new-frame);
}

.border-success-pos {

   border: 2px solid #1ca32353;
}



.header-lite {
   margin-top: 0px
}



.btn-menu-main {
   display: block;

}

.margin-btn-pay {
   margin-bottom: 14px;
}


.footer-text {
   font-size: 7px;
}

/* Others */
/* Estilos para el contenedor principal */
.kds-main {
   background-color: #000940;
}

.container-kds {
   display: flex;
   flex-direction: column;
   height: 100%;
}

/* Estilos para el header */
.header {
   /* height: 40px; */
}

/* Estilos para el main */
.main {
   /* Permite que el main ocupe el espacio restante vertical */
   flex: 1;
   /* Puedes agregar más estilos aquí */
}

.kds-header {
   position: sticky;
   top: 0;
   left: 0;
   z-index: 1999;
}

.logo {
   height: 40px;
}

.kds-container {
   width: 100%;
   height: 688px;
}

.kds-grid {
   margin-top: 50px;
   flex: 1;
   grid-template-columns: 3fr 1fr
}

.kds-list-orders,
.kds-view {
   height: 100%;
   /* background-color: #D9D9D9; */
}

/* .kds-tab{

} */

.kds-view-details {
   border-bottom: 2px dashed #c6c6c6;
}

.kds-list-orders {
   background-color: #000940;
}

.card-seen,
.card-no-seen {
   max-width: 18rem;
}

.container-header {
   flex: 3;
   height: 50px;
}

/* Estilos para el contenedor padre */
.container-parent {
   display: flex;
   height: 100vh;
}


/* Estilos para el contenedor izquierdo */
.container-left {
   flex: 3;
   /* El contenedor izquierdo ocupará el espacio restante */
   /* Se oculta cualquier contenido que exceda el tamaño del contenedor */
   overflow: auto;
   position: relative;
}

/* Estilos para el contenedor derecho */
.container-right {
   flex: 1;
   /* El contenedor derecho ocupará 3 veces el espacio del contenedor izquierdo */
   overflow: auto;
}

.kds-view-top {
   width: 100%;
   padding: 20px 0;
   position: absolute;
   top: 0;
   z-index: 1999;
}

.kds-view-bottom {
   width: 100%;
   padding: 20px 0;
   position: absolute;
   bottom: 0;
   z-index: 1999;
}

.flex-container {
   color: #000000;
   display: flex;
   justify-content: flex-start;
   align-items: stretch;
   flex-direction: column;
   flex-wrap: nowrap;
   align-content: flex-start;
   background-color: #ffffff;
   height: 100%;
   gap: 1px;

}

.flex-container>div {
   border-radius: 5px;
   padding: 2px 4px;
}


.item2 {
   /* flex:3 1 auto; */
   order: 0;
   flex-grow: 1;
   overflow: auto;
}



.kds-card-header {
   font-weight: 600;
   padding: 5px;
   margin-bottom: 0;
   color: #212748;
   /* background-color: rgba(0, 0, 0, 0.03); */
   background-color: #D9D9D9;
   border-bottom: 1px solid rgba(0, 0, 0, 0.175);
}

.kds-card-header:first-child {
   border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -webkit-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -moz-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -ms-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -o-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}

.kds-card-body {
   flex: 1 1 auto;
   padding: 0.5rem 0.5rem;
   background-color: transparent;
   /*color: var(--bs-card-color);*/
   border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -webkit-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -moz-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -ms-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -o-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;

   overflow: auto;
   height: 150px;

}

.kds-empty-orders {
   padding: 20px 10px;
}

.kds-img-empty {
   width: 100px;
   height: 100px;

}

.nav-link {
   display: block;
   padding: 0.5rem 1rem;
   font-weight: 600;
   color: #212748;
   ;
   text-decoration: none;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
   color: #ffffff;
   font-weight: 700;
   background-color: #212748;
}

.nav-pills .nav-link {
   background: 0 0;
   border: 0;
   border-radius: 0.375rem;
   -webkit-border-radius: 0.375rem;
   -moz-border-radius: 0.375rem;
   -ms-border-radius: 0.375rem;
   -o-border-radius: 0.375rem;
}


.tooltip-container {
   margin: 0 auto;
   display: inline-block;
}

/* EMPIEZA AQUÍ */

.tooltip-container {
   position: relative;
   cursor: pointer;
   padding: 2px 5px;
   margin: 0px 5px;
   background-color: #F2F2F2;
}

.tooltip-container:hover {
   color: #133154;
   background-color: #dcdcdc;
}

.tooltip-one {
   padding: 10px 20px;
   background: #000000bd;
   color: #fff;
   position: absolute;
   width: auto;
   border-radius: 5px;
   text-align: center;
   /* filter: drop-shadow(0 3px 5px #ccc); */
   line-height: 1.5;
   display: none;
   right: 10%;
   margin-top: 3px;
   margin-right: -43px;
   z-index: 1070;
}

/* .tooltip-one:after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 50%;
  margin-left: -9px;
  width: 18px;
  height: 18px;
  background: white;
  transform: rotate(45deg);
} */

.tooltip-trigger:hover+.tooltip-one {
   display: block;

}

.icon-label {
   font-size: 18px;
   font-weight: 800;
}

.bg-view {
   background-color: #C9FFEB;
}

.scale-up-horizontal-left {
   animation: scale-up-horizontal-left 0.4s
}

@keyframes scale-up-horizontal-left {
   0% {
      transform: scaleX(.4);
      transform-origin: left center
   }

   100% {
      transform: scaleX(1);
      transform-origin: left center
   }
}

/* Others */
/* * Global Vars  #0025B8*/
/* 
 Title: Commnets Format BEM 
 * class" block-name "
 ? element
 Block: Name Block  
 # block-name block-name--modifier-name
 !fesgfrgre
 ~~degrehreh5ehj
 End:Fin 
 || BLOCK  
 Section
 /* TODO:Revisar clasede input oculto*/


/* TODO:Revisar clasede input oculto*/
:root {
   --fuenteh: 'PT Sans', sans-serif;
   --fuente-main: 'Mulish', sans-serif;
   --primary: #3ca93f;
   --secondary: #2686BC;
   --primario-light: #39a93d1a;
   --primario-dark: #12971b;
   --rojo: rgb(202, 50, 50);
   --amarillo: rgb(238, 192, 28);
   --verde: #318e2c;
   --verde-2: #3FD300;
   --azul: #2a95bf;
   --azul-2: #2a95bf3a;
   --blanco: #ffffff;
   --white: #ffffff;
   --gris: #f0f2f586;
   --gris-2: #808080;
   --gris-3: #c0c0c022;
   --negro: #000;
   --bg-primario: #000;
   /* --primary-vending: #00b89a; */
   --primary-vending: #0025B8;
   --border-1: 9px;
   --border-2: 12px;
   --border-3: 14px;
   --border-4: 30px;
   --text: #3c424d;
   --new-main-0: #ffae00;
   --new-main-6: #F2F2F2;
   --new-main-8: #B3B2B3;
   --new-frame: #f0f0f0;
}


/*  Section:Globals Utilies */
/* Title: +++++++ Backgrounds +++++++ */

.bg-brand-primary {
   /* background: #396afc;
    background: -webkit-linear-gradient(to bottom, #2948ff, #396afc);
    background: linear-gradient(to bottom, #2948ff, #396afc); */

   background: #055aaf;
   background: -webkit-linear-gradient(to top, #042dbf, #0575E6);
   background: linear-gradient(to top, #042dbf, #0575E6);

}

.bg-brand-smartpay {
   background: #2bbe3a;
   background: -webkit-linear-gradient(to top, #26a337, #2bbe3a);
   background: linear-gradient(to top, #26a337, #2bbe3a);
}

.bg-brand-night {
   background: #000428;
   background: -webkit-linear-gradient(to bottom, #004e92, #000428);
   background: linear-gradient(to bottom, #004e92, #000428);
}

.bg-brand-light-blue {
   background: #00c6ff;
   background: -webkit-linear-gradient(to top, #0072ff, #00c6ff);
   background: linear-gradient(to top, #0072ff, #00c6ff);
}


/* Blur */
.bg-blur-sp {
   background-color: #29292946;
   backdrop-filter: blur(5px);
   /* border: 1px solid #e4e4e4a2; */
   /* box-shadow: 0px 0px 5px #222222; */
}


/* Utilits */
::-webkit-scrollbar {
   display: none;
}

.buttons-z {
   z-index: 9999;
}

/* Section: ++++++Init++++++ */
/*Title: +++++++ Main Layouts +++++++  */

.kiosk {
   display: flex;
   height: 100vh;
   display: flex;
   justify-content: center;

   /* border: 1px solid #a93c3c;
    background-color: var(--new-frame); */
}


.kiosk-container {
   background-color: #ffffff;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   /* align-content: flex-start; */
}

.kiosk-container_section {
   /* flex:3 1 auto;  */
   width: 100%;
   order: 0;
   flex-grow: 1;
   overflow: auto;
}

.kiosk-container_section_lista {
   padding: 120px 10px;
   /* border: 2px solid red; */
   overflow-x: auto;
}

.kiosk-lista {
   display: flex;
   height: 100%;
   width: 80px;
}

.kiosk-lista_items {
   cursor: pointer;
   position: relative;
   top: 0;
   transition: top ease 0.4s;
   -webkit-transition: top ease 0.4s;
   -moz-transition: top ease 0.4s;
   -ms-transition: top ease 0.4s;
   -o-transition: top ease 0.4s;
}

.kiosk-container_section_title {
   font-size: 50px;
}

/*~ Cards */

.kiosk-card-item {
   width: 20rem;
   height: 530px;
   border-radius: 30px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
}

/* .kiosk-card-item:last-child {
    padding-right: 20px;
    border: 6px solid red; 
  } */


.card-img-top {
   width: 100%;
   height: 100%;
   border-radius: 30px 30px 0px 0px;
   -webkit-border-radius: 30px 30px 0px 0px;
   -moz-border-radius: 30px 30px 0px 0px;
   -ms-border-radius: 30px 30px 0px 0px;
   -o-border-radius: 30px 30px 0px 0px;
}

.card-img-top>img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   border-radius: 30px 30px 0px 0px;
   -webkit-border-radius: 30px 30px 0px 0px;
   -moz-border-radius: 30px 30px 0px 0px;
   -ms-border-radius: 30px 30px 0px 0px;
   -o-border-radius: 30px 30px 0px 0px;
}

/* End: +++++ Init  +++++ */
/* Section: +++++ Delivery  +++++ */
.delivery {
   margin-top: 20px;
}

.kiosk-delivery {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-content: flex-start;
   gap: 1px;
}


.kiosk-delivery_header {
   padding: 3px;
   margin: 50px 10px;
   display: flex;
   justify-content: center;
   /* border: 2px solid #3c5ba9; */
   border-radius: 30px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
}

.kiosk-delivery_header_img {
   width: 250px;
   height: 120px;
   object-fit: cover;
   /* filter: drop-shadow(0 2px 5px rgba(158, 158, 158, 0.7)); */
}

.kiosk-delivery_title {
   font-size: 5rem;
   font-weight: 800;
   margin: 140px 10px 0px 0px;
}

.kiosk-header-img-categories {
   width: 300px;
   filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
   -webkit-filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
}

.kiosk-delivery_btn {
   margin-top: 150px;
   display: flex;
   justify-content: center;
}

.kiosk-delivery_btns>button {
   margin: 20px;
   border-radius: 60px;
   -webkit-border-radius: 60px;
   -moz-border-radius: 60px;
   -ms-border-radius: 60px;
   -o-border-radius: 60px;
}

.kiosk-text-card {
   font-size: 3.5rem;
   font-weight: 700;
}

.kiosk-text-categories {
   font-size: 2.7rem;
   font-weight: 700;
}

.kiosk-text-delivery {
   font-size: 3.5rem;
   font-weight: 700;
}


.btn-img-delivery {
   /* width:215px;  */
   font-size: 300px;
}

.btn-card-delivery {
   color: #0D6EFD;
   padding: 10rem 70px;

}


/* End: +++++ Delivery  +++++ */

/* Section: Cateogires */

/* Btns */

.btn-cart {
   fill: #fff;
   cursor: pointer;
   /* background-color: var(--primary-vending); */
   padding: 36px;
   width: 160px;
   height: 160px;
   border-radius: 50%;
   -webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   -ms-border-radius: 50%;
   -o-border-radius: 50%;
}

.kiosk-count {
   font-size: 3rem;
   top: -12px;
   left: -54px;
}

/* *Navigation Carrusel Buttons  */
.nav-button {
   font-size: 5rem;
   transform: translateY(-50%);
   background-color: rgba(255, 255, 255, .15);
   -webkit-backdrop-filter: blur(5px);
   backdrop-filter: blur(5px);
   color: #fff;
   padding: 5px 24px;
   cursor: pointer;
}

.nav-button:hover {
   background: #67B26F;
   background: -webkit-linear-gradient(to top, #4ca2cd, #67B26F);
   background: linear-gradient(to top, #4ca2cd, #67B26F);
}

.nav-button:focus {
   background-color: var(--primary-vending);
   border: 3px solid var(--primary-vending);
}

.nav-button:first-child {
   left: 0;
}

.nav-button:last-child {
   right: 0;
}

/* ~ Buttons Exits */
.button-ft_0 {
   fill: var(--blanco);
   cursor: pointer;
   background-color: var(--primario);
   width: 150px;
   height: 150px;
   padding: 20px 15px 20px 5px;
   border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
}


.button-ft_1 {
   fill: var(--blanco);
   cursor: pointer;
   background-color: var(--primary-vending);
   width: 150px;
   height: 150px;
   padding: 5px;
   border-radius: 50%;
   -webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   -ms-border-radius: 50%;
   -o-border-radius: 50%;
}


.button-ft,
.button-ft_0:active,
.button-ft_1:active {
   transform: scale(.92);
}

.container-button {
   margin: 0px 0px 5px 4px !important;
}

/* End: Categories */

/* Section: Product */

.kiosk-text-product {
   font-size: 2.7rem;
   font-weight: 700;
}

.kiosk-text-card-product {
   font-size: 2.7rem;
}

/*~ lIST CATEGORIES  */
.container-list-kiosk {
   height: 180px;
   padding: 2px 0px;
}

.container-list-kiosk_section {
   overflow: scroll;
   overflow-x: auto;
   white-space: nowrap;
   position: relative;
}


.container-list-kiosk::-webkit-scrollbar {
   width: 2px !important;
}

.container-list-kiosk ul {
   list-style: none;
   margin: 10px;
   padding: 0;
}

.container-list-kiosk li {
   display: inline-block;
   margin: 1px 9px;
}

.container-list-kiosk label {
   width: auto;
   font-size: 4.2rem;
}

.button-container-kk {
   display: inline-block;
   vertical-align: top;
   margin-right: 5px;
}

.button-container-kk img {
   width: 80px;
   height: 80px;
   margin: 5px;
}


/* Aplica un margen de 10px a todos los elementos de la lista */
.kiosk-card-item_product {
   margin: 0px;
}

/* Aplica un margen izquierdo de 20px a los elementos mayores que el tercer hijo */
.kiosk-card-item_product:nth-child(n + 4) {
   margin-top: 20px;
}


/* End: +++++Products+++++ */

/* Section: Information Products */


.kiosk-segments-container {
   margin-top: 1px !important;

}

.kiosk-segments-backgound {
   position: relative;
   width: 100%;
   height: 625px;
   box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
   border-radius: 0 0 20px 20px;
   -webkit-border-radius: 0 0 20px 20px;
   -moz-border-radius: 0 0 20px 20px;
   -ms-border-radius: 0 0 20px 20px;
   -o-border-radius: 0 0 20px 20px;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
}

.product-card-tv {
   border: 0px solid #000;
   width: 100%;
   height: 400px;
   border-radius: 0 0 20px 20px;
   -webkit-border-radius: 0 0 20px 20px;
   -moz-border-radius: 0 0 20px 20px;
   -ms-border-radius: 0 0 20px 20px;
   -o-border-radius: 0 0 20px 20px;
   background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
   position: absolute;
   bottom: 0;
}

.kiosk-description-product {

   font-size: 2.5rem;
   font-weight: 700;
}

/* ~List Crad Option Segments */
.kiosk-segments-items {
   border: #dfdfdf 1px solid;
   border-radius: 20px 20px 0 0;
   -webkit-border-radius: 20px 20px 0 0;
   -moz-border-radius: 20px 20px 0 0;
   -ms-border-radius: 20px 20px 0 0;
   -o-border-radius: 20px 20px 0 0;
}

label.radio-card {
   cursor: pointer;
}

label.radio-card .card-content-wrapper {
   scale: 1;
   background: #ffffff;
   border: solid 2px #e3e3e3;
   border-radius: 9px;
   width: 280px;
   height: 460px;
   padding: 5px;
   position: relative;
   display: grid;
   box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
   transition: 200ms linear;
   -webkit-border-radius: 9px;
   -moz-border-radius: 9px;
   -ms-border-radius: 9px;
   -o-border-radius: 9px;
}

label.radio-card .check-icon {
   width: 10px;
   height: 10px;
   display: flex;
   border: solid 2px #e3e3e3;
   border-radius: 50%;
   transition: 200ms linear;
   position: relative;
   justify-content: flex-end;
   -webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   -ms-border-radius: 50%;
   -o-border-radius: 50%;
}

label.radio-card .check-icon:before {
   content: "";

   /* background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 7px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear; */
   opacity: 0;
}

label.radio-card input[type=radio] {
   visibility: hidden;
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
}

label.radio-card input[type=radio]:checked+.card-content-wrapper {
   border: 3px solid #0045f4;
   color: #000000;
   background-color: #0048ff15;
   /*background: #055aaf;
     */
   /*background: -webkit-linear-gradient(to bottom, #042dbf, #0575E6);
     background: linear-gradient(to bottom, #042dbf, #0575E6);
     */

   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10x;
   -ms-border-radius: 10x;
   -o-border-radius: 10x;
}

label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon {
   background: #3057d5;
   border-color: #3056d5c5;
   transform: scale(1.2);
}

label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon:before {
   transform: scale(1);
   opacity: 1;
}

label.radio-card input[type=radio]:focus+.card-content-wrapper .check-icon {
   box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
   border-color: #3056d5;
}

label.radio-card .card-content img {
   width: 100%;
   margin-bottom: 10px;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
   scale: 1;
}

label.radio-card .card-content h4 {
   width: 239px;
   font-size: 30px;
   letter-spacing: -0.24px;
   text-align: center;
   font-weight: 500;
   color: #000;
   margin-bottom: 10px;
   font-weight: 800;
}

label.radio-card .card-content h5 {
   font-size: 50px;
   line-height: 1.4;
   text-align: center;
   color: #22a009;
   font-weight: 800;
}

.kiosk-lista-container-product {
   padding: 15px 30px;
   /* border: 2px solid red; */
   overflow-x: auto;
}


.container-card-content-wrapper {
   margin-bottom: 2px;

}

.container-card-content-wrapper:last-child {
   margin-bottom: 180px;
}

/* End: Information Products */

/* Section: Order Sumary */

/* ~ Order Summary */
.title-order-section {
   font-size: 5rem;
   font-weight: 800;
}

.button-discont {
   font-size: 2.8rem
}

.order-item {
   margin: 10px 10px;
}

.order-item:first-child {
   margin: 40px 10px;
}

.img-order-summary {
   width: 270px;
   height: 270px;
   object-fit: cover;
}

.text-count-orders {
   font-size: 2.5rem;
}

.image-empty {
   width: 300px;
   height: 300px;
}

.text-msg-empty-orders {
   font-size: 40px;
}

.title-product-text {
   font-size: 3.5rem;
}

.summary-text {
   font-size: 1.9rem;

}

.detail-text {
   font-size: 1.6rem;
}

.btn-dash {
   background-color: red;
}

.btn-plus {
   background-color: green;
}

.btn-dash:active {
   transform: scale(.85);
   -webkit-transform: scale(.85);
   -moz-transform: scale(.85);
   -ms-transform: scale(.85);
   -o-transform: scale(.85);
}

.btn-plus:active {
   transform: scale(.85);
   -webkit-transform: scale(.85);
   -moz-transform: scale(.85);
   -ms-transform: scale(.85);
   -o-transform: scale(.85);
}

.icon-delete-kk {
   font-size: 20px;
}

.count-items {
   font-size: 60px;
}


.dash-icon,
.plus-icon {
   color: white;
   font-size: 60px;
   font-weight: 800;
   padding: 10px 20px;

   border-radius: 20px;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   -ms-border-radius: 20px;
   -o-border-radius: 20px;
}

.btn-add-order {
   font-size: 3.5rem;
   font-weight: 700;
   padding: 8px;
}

.btn-cancel-order {
   font-size: 4rem;
   font-weight: 700;
   padding: 12px;
}

.btn-send-order {
   font-size: 4rem;
   font-weight: 700;
   padding: 12px;
}

/* Totals */
.text-subtitel-order {
   font-size: 2.8rem;
}

.total-container {
   border-top: 1px dashed #80808042;
}

.text-total-order {
   padding: 10px 0px;
   font-size: 3.5rem;
}

/* ~Modal Discounts */

.kiosk-img-discount {
   width: 50%;
   height: 50%;
}

.kiosk-text-discount {
   font-size: 40px;
   font-weight: 800;
   margin: 60px 0px 0px 0px;
}

/* Title:+++++++ Borders +++++++ */
.kiosk-border-4 {
   border-radius: var(--border-4);
   -webkit-border-radius: var(--border-4);
   -moz-border-radius: var(--border-4);
   -ms-border-radius: var(--border-4);
   -o-border-radius: var(--border-4);
}

.scroll-fast {
   scroll-behavior: smooth;
}

.kiosk-text-title-lang {
   font-size: 3rem;
   font-weight: 800;
   margin: 0px 0px 35px 0px;
}

.input-sp-qr {
   top: -30px;
   visibility: hidden;
}

.kiosk-container-main {
   color: transparent;
}

/* !End Global **/

/* || Sections || Steps **/


/*~ +++++ Buttons ++++++ */









/* ~ Others Styles  */

.key_1 {
   background-color: #eaeaeaa4;
   border: 2px solid var(--new-main-8);
   border-radius: 30px;
   /* width: 5rem;
    font-size: 3rem;
    margin: 5px; */
}


.kiosk-key-letter {
   font-size: 2.5rem;
   font-weight: 800;
   padding: 30px;
   border: 2px solid var(--new-main-8);
   border-radius: 30px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
   margin: 2px;
}



.kiosk-key-letter:active {
   transform: scale(0.95);
   -webkit-transform: scale(0.95);
   -moz-transform: scale(0.95);
   -ms-transform: scale(0.95);
   -o-transform: scale(0.95);
}

.kiosk-key-letter:focus {
   color: var(--blanco);
   background-color: var(--primary-vending);
   transform: scale(0.95);
   -webkit-transform: scale(0.95);
   -moz-transform: scale(0.95);
   -ms-transform: scale(0.95);
   -o-transform: scale(0.95);
}

.keypadPrimary-input_1 {
   color: var(--primary-vending);
   width: 100%;
   margin: 2.5rem 0;
   padding: 15px 0;
   font-size: 5.5rem;
   font-weight: 700;
   text-align: center;
   border: 2px solid rgb(226, 226, 226);
   letter-spacing: 25px;
}

/*  
 .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100% !important;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: 60px;
    outline: 0;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
 } */

/* 
 @media (min-width: 576px) {
    .modal-dialog-kk {
       max-width: 790px;
       margin-right: auto;
       margin-left: auto;
    }
 
 }  */


.modal-footer-sp_0 {
   display: flex;
   flex-shrink: 0;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-end;
   padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
   background-color: var(--bs-modal-footer-bg);
   border-top: 0 0 !important;
   border-bottom-right-radius: 0 !important;
   border-bottom-left-radius: 0 !important;
}

.modal-footer-sp_1 {
   margin: 10px;
   display: flex;
   flex-shrink: 0;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-end;
   padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
   background-color: var(--bs-modal-footer-bg);
   border-top: 0 0 !important;
   border-bottom-right-radius: 0 !important;
   border-bottom-left-radius: 0 !important;
}

/* ! -----End Delivery----- */
/* **************************/
/* ? ------Modal Languaje------ */
.btn-exit_0 {
   fill: var(--white);
   cursor: pointer;
   background-color: var(--primario);
   width: 120px;
   height: 120px;
   padding: 20px 15px 20px 5px;
   border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
   -webkit-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
   -moz-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
   -ms-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
   -o-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
}

.btn-exit_1 {
   fill: var(--white);
   cursor: pointer;
   background-color: var(--primario);
   width: 120px;
   height: 120px;
   padding: 5px;
   margin-left: 40px;
   border-radius: 50%;
   -webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   -ms-border-radius: 50%;
   -o-border-radius: 50%;
}


.btn-exit_0:hover {
   transform: scale(.92);
   background-color: var(--primario-dark);
   border: 10px solid #000;
}

.btn-exit_0:active {
   transform: scale(.92);
   border: 10px solid #000;
}

.btn-exit_1:active {
   transform: scale(.92);
   border: 10px solid #000;
}

/* ! ----End Languaje---- */
/* **************************/


/* **************************/
/* ? ------Categories------ */

/* Title: Carrusel Promotions */



.kiosk-lista-container-product {
   padding: 15px 30px;
   /* border: 2px solid red; */
   overflow-x: auto;
}



.kiosk-lista-container::-webkit-scrollbar {
   display: none;
}





.kiosk-categories>div {
   /* margin: 15px 1px;
    padding: 8px; */
   border: 0px solid red;
}

/* .carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
 } */

.carousel-container {
   overflow: hidden;
   width: 100%;
   margin: 0;
}

.carousel-item {
   display: none;
}

.carousel-item img {
   width: 100%;
   height: 80vh;
   /* background-size: contain; */
   object-fit: cover;
   border: 0px solid var(--new-main-8);
   /* border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px; */
}

.active {
   display: block;
}

.btn-direction {
   top: 50%;
}





/* ! ----End Categories---- */
/* **************************/
/* ? -------Products------- */
/* .btn-exit-footer {
    position: absolute;
    bottom: 0;
    fill: var(--white);
    cursor: pointer;
    background-color: var(--primary-vending);
    padding: 20px 34px 0px 10px;
    width: 160px;
    height: 160px;
    border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
 
 
 
 } */


.btn-exit-footer {
   position: absolute;
   bottom: 0;
   fill: var(--white);
   cursor: pointer;
   background-color: var(--primary-vending);
   padding: 20px 34px 0px 10px;
   /* width: 160px;
    height: 160px; */
   border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;

}




.btn-exit-footer:active {
   transform: scale(.92);
   border: 10px solid #000;
}

.button-ps {
   margin-bottom: 150px;
}

.bg-transparent-sp {
   background-color: transparent;
}








/* ! -----End Products----- */
/* **************************/
/* ? ----Info Products ---- */
/* ! --End Info Products--- */
/* **************************/
/* ? ----Order Summary----- */
/* ! ----Order Summary----- */
/* **************************/
/* ? -------Payments------- */
/* ! -------Payments------- */
/* **************************/
/* ? ----Status Payment---- */
/* ! ----Status Payment---- */
/* **************************/
/* ? ----End Transaction--- */
/* ! ----End Transaction--- */

.kiosk-subtitle {
   font-size: 3.5rem;
   font-weight: 800;
}

.kiosk-subtitle-md {
   font-size: 2.5rem;
   font-weight: 800;

}

.kiosk-subtitle-item {
   font-size: 2.5rem;
   font-weight: 800;
   width: 480px;
}

.kiosk-subtitle-s {
   font-size: 2rem;
   font-weight: 500;
}

/* Text Floating */
.kiosk-button {
   position: fixed;
   top: 0px;
   right: 0px;
}





.btn-img-categorie {
   width: 300px;

}


.btn-return-kiosk-container {
   position: fixed;
   top: 0;
   left: 0;
   padding: 15px;
}

.btn-return-kiosk {
   background-color: #1479de;
   color: #ecf0f1;
   border: none;
   padding: 10px 35px;
   font-size: 3.2rem;
   font-weight: 700;
   cursor: pointer;
   border-radius: 0 40px 40px 0;
   -webkit-border-radius: 0 40px 40px 0;
   -moz-border-radius: 0 40px 40px 0;
   -ms-border-radius: 0 40px 40px 0;
   -o-border-radius: 0 40px 40px 0;
}

.btn-return-kiosk:hover {
   background-color: #1271ca;
}


.kiosk-title-segments {
   font-size: 30px;
   font-weight: 700;
}

.kiosk-price-segments {
   font-size: 30px;
   font-weight: 800;
}

.kiosk-img-segments {
   width: 250px;
   object-fit: cover;
   border-radius: 30px !important;
   -webkit-border-radius: 30px !important;
   -moz-border-radius: 30px !important;
   -ms-border-radius: 30px !important;
   -o-border-radius: 30px !important;
}



.kiosk-banner-promotion {
   padding: 120px;
   border-radius: 30px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
   -webkit-border-radius: 30px;
}



.form-check-input:checked+.form-checked-content {
   opacity: .5;
}

.form-check-input-placeholder {
   border-style: dashed;
}

[contenteditable]:focus {
   outline: 0;
}

.list-group-checkable .list-group-item {
   cursor: pointer;
}

.list-group-item-check {
   position: absolute;
   clip: rect(0, 0, 0, 0);
}

.list-group-item-check:hover+.list-group-item {
   background-color: var(--bs-secondary-bg);
}

.list-group-item-check:checked+.list-group-item {
   color: #fff;
   background-color: var(--bs-primary);
   border-color: var(--bs-primary);
}

.list-group-item-check[disabled]+.list-group-item,
.list-group-item-check:disabled+.list-group-item {
   pointer-events: none;
   filter: none;
   opacity: .5;
}

.list-group-radio .list-group-item {
   cursor: pointer;
   border-radius: .5rem;
}

.list-group-radio .form-check-input {
   z-index: 2;
   margin-top: -.5em;
}



.list-group-radio .form-check-input:checked+.list-group-item {
   background-color: var(--bs-body);
   border-color: var(--bs-primary);
   box-shadow: 0 0 0 2px var(--bs-primary);
}

.list-group-radio .form-check-input[disabled]+.list-group-item,
.list-group-radio .form-check-input:disabled+.list-group-item {
   pointer-events: none;
   filter: none;
   opacity: .5;
}


.kiosk-delivery-footer {
   display: flex;
   justify-content: space-between;
}

.round-kiosk-button {
   width: 4rem;
   height: 4rem;
   border-radius: 50%;
   background-color: var(--primario);
   font-size: 44px;
   text-align: center;
   line-height: 50px;
}

.count-kiosk {
   font-size: 3rem;
}

.count-kiosk-os {
   font-size: 3.5rem;
}

.round-kiosk-button:active {
   transform: scale(.85);
   -webkit-transform: scale(.85);
   -moz-transform: scale(.85);
   -ms-transform: scale(.85);
   -o-transform: scale(.85);
}

/* Animations */
.scale-up-top {
   animation: scale-up-top 0.4s;
}

@keyframes scale-up-top {
   0% {
      transform: scale(.5);
      transform-origin: center top
   }

   100% {
      transform: scale(1);
      transform-origin: center top
   }
}


.scale-up-horizontal-left {
   animation: scale-up-horizontal-left 0.4s;
   -webkit-animation: scale-up-horizontal-left 0.4s;
}

@keyframes scale-up-horizontal-left {
   0% {
      transform: scaleX(.4);
      transform-origin: left center;
      -webkit-transform: scaleX(.4);
      -moz-transform: scaleX(.4);
      -ms-transform: scaleX(.4);
      -o-transform: scaleX(.4);
   }

   100% {
      transform: scaleX(1);
      transform-origin: left center
   }
}




@media screen and (min-width: 1080px) and (min-height: 1920px) {
   .round-kiosk-button {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background-color: var(--primario);
      color: rgb(159, 13, 13);
      font-size: 44px;
      text-align: center;
      line-height: 50px;
   }

}






.radio-tile-group {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
}


.radio-tile-group .input-container {
   position: relative;
   width: 100%;
   height: 100%;
   margin: 0.5rem;
}

.radio-tile-group .input-container .radio-button {
   opacity: 0;
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   margin: 0;
   cursor: pointer;
}

.radio-tile-group .input-container .radio-tile {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   /* background: #fff; */
   border: 5px solid #e8e8e8;
   border-radius: 30px;
   padding: 8rem 1rem;
   transition: transform 300ms ease;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
}


.radio-tile-group .input-container .icon-pay {
   fill: #079ad9;
   width: 3rem;
   height: 3rem;
}

.radio-tile-group .input-container .radio-tile-label {
   text-align: center;
   font-size: 2.2rem;
   font-weight: 600;
   text-transform: uppercase;
   letter-spacing: 1px;

}

.radio-tile-group .input-container .radio-button:checked+.radio-tile {
   background-color: #079ad9;
   border: 2px solid #079ad9;
   color: white;
   transform: scale(1.1, 1.1);
   font-weight: 800;
}

.radio-tile-group .input-container .radio-button:checked+.radio-tile .icon-pay {
   fill: white;
   background-color: #079ad9;
   font-weight: 800;
}

.icon-pay {
   width: 170px;
}

.img-pay-met-kk {
   width: 170px;
   height: 170px;
   object-fit: contain;
}

.radio-tile-group .input-container .radio-button:checked+.radio-tile .radio-tile-label {
   color: white;
   /* background-color: #07c0d9; */
   font-weight: 800;
}

.radio-tile-group .input-container .radio-button:focus {
   border: 10px solid #fa1b1b;
   border-radius: 30px;
}




.icon-delete-kk {
   font-size: 60px;
}

.btn-warning-kk {
   border: #0025B8 dashed 3px;
   color: #0025B8;
}

.btn-warning-kk:hover {
   border: #0025B8 dashed 3px;

   color: #0025B8;
   background-color: #0025b820;
}

.btn-warning-kk:active {
   transform: scale(.85);
   -webkit-transform: scale(.85);
   -moz-transform: scale(.85);
   -ms-transform: scale(.85);
   -o-transform: scale(.85);
}

.kk-text-2 {
   font-size: 23px;
   font-weight: 700;
}


/* .scale-hover:active{
    transform: scale(.85);
    -webkit-transform: scale(.85);
    -moz-transform: scale(.85);
    -ms-transform: scale(.85);
    -o-transform: scale(.85);
 }*/



.scale-hover-sm:focus {
   transform: scale(1.10) !important;
   -webkit-transform: scale(1.10) !important;
   -moz-transform: scale(1.10) !important;
   -ms-transform: scale(1.10) !important;
   -o-transform: scale(1.10) !important;
}




.icon-focus {
   width: 100px;
   height: auto;
   display: none;
}

.icon-focus:focus::before {
   opacity: 1;
}

.icon-focus:not(:focus)::before {
   display: none;
}

.postition-icon-sm {
   top: 65%;
   z-index: 999;
   left: 30%;
}

.postition-icon-md {
   bottom: -10%;
   z-index: 999;
   left: 37%;
}

.postition-icon-promotion {
   top: 55%;
   left: 10%;
}

.postition-icon-categories {
   bottom: -15%;
   right: 40%;
   z-index: 999;
}

.position-icon-segments {
   bottom: -21%;
   right: 40%;
   z-index: 999;

}

.postition-icon-count {
   bottom: -21%;
   right: 40%;
   z-index: 999;
}

.postition-icon-count {
   bottom: -70%;
   z-index: 999;
   left: -15%;
}

.postition-icon-product {
   bottom: 4%;
   left: 0%;
   z-index: 999;
}

.postition-icon-btn-accions {
   bottom: -10%;
   left: 80%;
   z-index: 999;
}

.postition-icon-key {
   bottom: -40%;
   left: 0%;
   z-index: 999;
}


.postition-icon-list {
   bottom: -40%;
   right: 0%;
   z-index: 999;
}

.postition-icon-dis {
   bottom: -75%;
   right: 0%;
   z-index: 999;
}


.postition-icon-pays {
   bottom: 10%;
   z-index: 999;
   left: 37%;
}


.focus-pay:focus {
   border: 10px solid rgb(240, 188, 18);
   border-radius: 30px;
}

.postition-icon-details {
   top: 0;
   left: 22%;
   z-index: 999;
}

.postition-icon-pays {
   bottom: -10%;
   z-index: 999;
   left: 5%;
}

.postition-icon-edit {
   top: 50%;
   z-index: 999;
   right: 50%;
}

details:focus {
   border: none;
}


/* !Revisar  */

.list-group-item {
   background-color: var(--bs-light);
   border: 1px solid #cfcfcf73;
   border-radius: 1px;
   -webkit-border-radius: 1px;
   -moz-border-radius: 1px;
   -ms-border-radius: 1px;
   -o-border-radius: 1px;
}

.list-group-radio .list-group-item:hover,
.list-group-radio .list-group-item:focus {
   color: var(--blanco) !important;
   background-color: var(--primary-vending) !important;
}

.list-group-item-check:hover+.list-group-item {
   background-color: var(--bs-light);
   border: 1px solid #007bff;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}

.list-group-item-check:checked+.list-group-item {
   color: #007bff;
   ;
   background-color: var(--bs-light);
   border: 1px solid #007bff;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}


.list-group-item-check[disabled]+.list-group-item,
.list-group-item-check:disabled+.list-group-item {
   pointer-events: none;
   filter: none;
   opacity: .5;
}


/* Animation */

.scale-up-left {
   animation: scale-up-left 0.4s;
}

@keyframes scale-up-left {
   0% {
      transform: scale(.5);
      transform-origin: left center
   }

   100% {
      transform: scale(1);
      transform-origin: left center
   }
}



.bounce-left {
   animation: bounce-left 1.2s linear both;
   transition: 0.5s ease-in
}

@keyframes bounce-left {
   0% {
      transform: translateX(-48px);
      animation-timing-function: ease-in;
      opacity: 1
   }

   24% {
      opacity: 1
   }

   40% {
      transform: translateX(-26px);
      animation-timing-function: ease-in
   }

   65% {
      transform: translateX(-13px);
      animation-timing-function: ease-in
   }

   82% {
      transform: translateX(-6.5px);
      animation-timing-function: ease-in
   }

   93% {
      transform: translateX(-4px);
      animation-timing-function: ease-in
   }

   25%,
   55%,
   75%,
   87%,
   98% {
      transform: translateX(0);
      animation-timing-function: ease-out
   }

   100% {
      transform: translateX(0);
      animation-timing-function: ease-out;
      opacity: 1
   }
}

.msg-pay {
   margin: 25rem 0;
}

.msg-pay-bottom {
   margin: 10rem 0;
}

.msg-position {
   top: 10%;

}

.display-5-kk {

   font-size: calc(1.425rem + 2.1vw);
   font-weight: 300;
   line-height: 1.2;

}



.kiosk-container-main {
   color: transparent;
}

/* !End Global **/

/* || Sections || Steps **/
/* Title: Delivery------- */
/* Title: General Styles  */

.kiosk-delivery_header {
   padding: 3px;
   margin: 50px 0px;
   display: flex;
   justify-content: center;
   /* border: 2px solid #3c5ba9; */
   border-radius: 30px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
}


.kiosk-header-img-categories {
   width: 300px;
   filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
   -webkit-filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
}











/* ~ Others Styles  */

.key_1 {
   background-color: #eaeaeaa4;
   border: 2px solid var(--new-main-8);
   border-radius: 30px;
   /* width: 5rem;
    font-size: 3rem;
    margin: 5px; */
}


.kiosk-key-letter {
   font-size: 2.5rem;
   font-weight: 800;
   padding: 30px;
   border: 2px solid var(--new-main-8);
   border-radius: 30px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
   margin: 2px;
}



.kiosk-key-letter:active {
   transform: scale(0.95);
   -webkit-transform: scale(0.95);
   -moz-transform: scale(0.95);
   -ms-transform: scale(0.95);
   -o-transform: scale(0.95);
}

.kiosk-key-letter:focus {
   color: var(--blanco);
   background-color: var(--primary-vending);
   transform: scale(0.95);
   -webkit-transform: scale(0.95);
   -moz-transform: scale(0.95);
   -ms-transform: scale(0.95);
   -o-transform: scale(0.95);
}

.keypadPrimary-input_1 {
   color: var(--primary-vending);
   width: 100%;
   margin: 2.5rem 0;
   padding: 15px 0;
   font-size: 5.5rem;
   font-weight: 700;
   text-align: center;
   border: 2px solid rgb(226, 226, 226);
   letter-spacing: 25px;
}


/* 
 @media (min-width: 576px) {
    .modal-dialog-kk {
       max-width: 790px;
       margin-right: auto;
       margin-left: auto;
    }
 
 }  */


.modal-footer-sp_0 {
   display: flex;
   flex-shrink: 0;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-end;
   padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
   background-color: var(--bs-modal-footer-bg);
   border-top: 0 0 !important;
   border-bottom-right-radius: 0 !important;
   border-bottom-left-radius: 0 !important;
}

.modal-footer-sp_1 {
   margin: 10px;
   display: flex;
   flex-shrink: 0;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-end;
   padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
   background-color: var(--bs-modal-footer-bg);
   border-top: 0 0 !important;
   border-bottom-right-radius: 0 !important;
   border-bottom-left-radius: 0 !important;
}

/* ! -----End Delivery----- */
/* **************************/
/* ? ------Modal Languaje------ */
.btn-exit_0 {
   fill: var(--white);
   cursor: pointer;
   background-color: var(--primario);
   width: 120px;
   height: 120px;
   padding: 20px 15px 20px 5px;
   border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
   -webkit-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
   -moz-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
   -ms-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
   -o-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
}

.btn-exit_1 {
   fill: var(--white);
   cursor: pointer;
   background-color: var(--primario);
   width: 120px;
   height: 120px;
   padding: 5px;
   margin-left: 40px;
   border-radius: 50%;
   -webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   -ms-border-radius: 50%;
   -o-border-radius: 50%;
}


.btn-exit_0:hover {
   transform: scale(.92);
   background-color: var(--primario-dark);
   border: 10px solid #000;
}

.btn-exit_0:active {
   transform: scale(.92);
   border: 10px solid #000;
}

.btn-exit_1:active {
   transform: scale(.92);
   border: 10px solid #000;
}

/* ! ----End Languaje---- */
/* **************************/


/* **************************/
/* ? ------Categories------ */

/* Title: Carrusel Promotions */

.categories {
   bottom: 2%;

}





.kiosk-lista-container::-webkit-scrollbar {
   display: none;
}



.kiosk-categories {
   background-color: var(--blanco);
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-content: flex-start;
   gap: 1px;
}

.kiosk-categories>div {
   /* margin: 15px 1px;
    padding: 8px; */
   border: 0px solid red;
}

/* .carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
 } */

.carousel-container {
   overflow: hidden;
   width: 100%;
   margin: 0;
}

.carousel-item {
   display: none;
}

.carousel-item img {
   width: 100%;
   height: 80vh;
   /* background-size: contain; */
   object-fit: cover;
   border: 0px solid var(--new-main-8);
   /* border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px; */
}

.active {
   display: block;
}

.btn-direction {
   top: 50%;
}




/* ! ----End Categories---- */
/* **************************/
/* ? -------Products------- */
/* .btn-exit-footer {
    position: absolute;
    bottom: 0;
    fill: var(--white);
    cursor: pointer;
    background-color: var(--primary-vending);
    padding: 20px 34px 0px 10px;
    width: 160px;
    height: 160px;
    border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
 
 
 
 } */


.btn-exit-footer {
   position: absolute;
   bottom: 0;
   fill: var(--white);
   cursor: pointer;
   background-color: var(--primary-vending);
   padding: 20px 34px 0px 10px;
   /* width: 160px;
    height: 160px; */
   border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;

}



.button-ft_0 {
   fill: var(--white);
   cursor: pointer;
   background-color: var(--primario);
   width: 150px;
   height: 150px;
   padding: 20px 15px 20px 5px;
   border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
}




.btn-exit-footer:active {
   transform: scale(.92);
   border: 10px solid #000;
}

.button-ps {
   margin-bottom: 150px;
}

.bg-transparent-sp {
   background-color: transparent;
}

.kiosk-subtitle {
   font-size: 3.5rem;
   font-weight: 800;
}

.kiosk-subtitle-md {
   font-size: 2.5rem;
   font-weight: 800;

}

.kiosk-subtitle-item {
   font-size: 2.5rem;
   font-weight: 800;
   width: 480px;
}

.kiosk-subtitle-s {
   font-size: 2rem;
   font-weight: 500;
}

/* Text Floating */
.kiosk-button {
   position: fixed;
   top: 0px;
   right: 0px;
}





.btn-img-categorie {
   width: 300px;

}


.btn-return-kiosk-container {
   position: fixed;
   top: 0;
   left: 0;
   padding: 15px;
}

.btn-return-kiosk {
   background-color: #1479de;
   color: #ecf0f1;
   border: none;
   padding: 10px 35px;
   font-size: 3.2rem;
   font-weight: 700;
   cursor: pointer;
   border-radius: 0 40px 40px 0;
   -webkit-border-radius: 0 40px 40px 0;
   -moz-border-radius: 0 40px 40px 0;
   -ms-border-radius: 0 40px 40px 0;
   -o-border-radius: 0 40px 40px 0;
}

.btn-return-kiosk:hover {
   background-color: #1271ca;
}


.kiosk-title-segments {
   font-size: 30px;
   font-weight: 700;
}

.kiosk-price-segments {
   font-size: 30px;
   font-weight: 800;
}

.kiosk-img-segments {
   width: 250px;
   object-fit: cover;
   border-radius: 30px !important;
   -webkit-border-radius: 30px !important;
   -moz-border-radius: 30px !important;
   -ms-border-radius: 30px !important;
   -o-border-radius: 30px !important;
}

.kiosk-banner-promotion {
   padding: 120px;
   border-radius: 30px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
   -webkit-border-radius: 30px;
}



.form-check-input:checked+.form-checked-content {
   opacity: .5;
}

.form-check-input-placeholder {
   border-style: dashed;
}

[contenteditable]:focus {
   outline: 0;
}

.list-group-checkable .list-group-item {
   cursor: pointer;
}

.list-group-item-check {
   position: absolute;
   clip: rect(0, 0, 0, 0);
}

.list-group-item-check:hover+.list-group-item {
   background-color: var(--bs-secondary-bg);
}

.list-group-item-check:checked+.list-group-item {
   color: #fff;
   background-color: var(--bs-primary);
   border-color: var(--bs-primary);
}

.list-group-item-check[disabled]+.list-group-item,
.list-group-item-check:disabled+.list-group-item {
   pointer-events: none;
   filter: none;
   opacity: .5;
}

.list-group-radio .list-group-item {
   cursor: pointer;
   border-radius: .5rem;
}

.list-group-radio .form-check-input {
   z-index: 2;
   margin-top: -.5em;
}



.list-group-radio .form-check-input:checked+.list-group-item {
   background-color: var(--bs-body);
   border-color: var(--bs-primary);
   box-shadow: 0 0 0 2px var(--bs-primary);
}

.list-group-radio .form-check-input[disabled]+.list-group-item,
.list-group-radio .form-check-input:disabled+.list-group-item {
   pointer-events: none;
   filter: none;
   opacity: .5;
}


.kiosk-delivery-footer {
   display: flex;
   justify-content: space-between;
}

.round-kiosk-button {
   width: 4rem;
   height: 4rem;
   border-radius: 50%;
   background-color: var(--primario);
   font-size: 44px;
   text-align: center;
   line-height: 50px;
}


.round-kiosk-button:active {
   transform: scale(.85);
   -webkit-transform: scale(.85);
   -moz-transform: scale(.85);
   -ms-transform: scale(.85);
   -o-transform: scale(.85);
}

/* Animations */
.scale-up-top {
   animation: scale-up-top 0.4s;
}

@keyframes scale-up-top {
   0% {
      transform: scale(.5);
      transform-origin: center top
   }

   100% {
      transform: scale(1);
      transform-origin: center top
   }
}


.scale-up-horizontal-left {
   animation: scale-up-horizontal-left 0.4s
}

@keyframes scale-up-horizontal-left {
   0% {
      transform: scaleX(.4);
      transform-origin: left center;
      -webkit-transform: scaleX(.4);
      -moz-transform: scaleX(.4);
      -ms-transform: scaleX(.4);
      -o-transform: scaleX(.4);
   }

   100% {
      transform: scaleX(1);
      transform-origin: left center
   }
}








.prodcut-card-tv {
   border: 0px solid #000;
   width: 100%;
   height: 90%;
   background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
   position: absolute;
   bottom: 0;

}

.radio-tile-group {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
}


.radio-tile-group .input-container {
   position: relative;
   width: 100%;
   height: 100%;
   margin: 0.5rem;
}

.radio-tile-group .input-container .radio-button {
   opacity: 0;
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   margin: 0;
   cursor: pointer;
}

.radio-tile-group .input-container .radio-tile {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   /* background: #fff; */
   border: 5px solid #e8e8e8;
   border-radius: 30px;
   padding: 8rem 1rem;
   transition: transform 300ms ease;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
}


.radio-tile-group .input-container .icon-pay {
   fill: #079ad9;
   width: 3rem;
   height: 3rem;
}

.radio-tile-group .input-container .radio-tile-label {
   text-align: center;
   font-size: 2.2rem;
   font-weight: 600;
   text-transform: uppercase;
   letter-spacing: 1px;

}

.radio-tile-group .input-container .radio-button:checked+.radio-tile {
   background-color: #079ad9;
   border: 2px solid #079ad9;
   color: white;
   transform: scale(1.1, 1.1);
   font-weight: 800;
}

.radio-tile-group .input-container .radio-button:checked+.radio-tile .icon-pay {
   fill: white;
   background-color: #079ad9;
   font-weight: 800;
}

.icon-pay {
   width: 170px;
}

.img-pay-met-kk {
   width: 250px;
   height: 370px;
   object-fit: contain;
}

.radio-tile-group .input-container .radio-button:checked+.radio-tile .radio-tile-label {
   color: white;
   /* background-color: #07c0d9; */
   font-weight: 800;
}

.radio-tile-group .input-container .radio-button:focus {
   border: 10px solid #fa1b1b;
   border-radius: 30px;
}


.btn-warning-kk {
   border: #0025B8 dashed 3px;
   color: #0025B8;
}

.btn-warning-kk:hover {
   border: #0025B8 dashed 3px;

   color: #0025B8;
   background-color: #0025b820;
}

.btn-warning-kk:active {
   transform: scale(.85);
   -webkit-transform: scale(.85);
   -moz-transform: scale(.85);
   -ms-transform: scale(.85);
   -o-transform: scale(.85);
}

.kk-text-2 {
   font-size: 23px;
   font-weight: 700;
}


/* .scale-hover:active{
    transform: scale(.85);
    -webkit-transform: scale(.85);
    -moz-transform: scale(.85);
    -ms-transform: scale(.85);
    -o-transform: scale(.85);
 }*/



.scale-hover-sm:focus {
   transform: scale(1.10) !important;
   -webkit-transform: scale(1.10) !important;
   -moz-transform: scale(1.10) !important;
   -ms-transform: scale(1.10) !important;
   -o-transform: scale(1.10) !important;
}




.icon-focus {
   width: 100px;
   height: auto;
   display: none;
}

.icon-focus:focus::before {
   opacity: 1;
}

.icon-focus:not(:focus)::before {
   display: none;
}

.postition-icon-sm {
   top: 65%;
   z-index: 999;
   left: 30%;
}

.postition-icon-md {
   bottom: -10%;
   z-index: 999;
   left: 37%;
}

.postition-icon-promotion {
   top: 55%;
   left: 10%;
}

.postition-icon-categories {
   bottom: -15%;
   right: 40%;
   z-index: 999;
}

.position-icon-segments {
   bottom: -21%;
   right: 40%;
   z-index: 999;

}

.postition-icon-count {
   bottom: -21%;
   right: 40%;
   z-index: 999;
}

.postition-icon-count {
   bottom: -70%;
   z-index: 999;
   left: -15%;
}

.postition-icon-product {
   bottom: 4%;
   left: 0%;
   z-index: 999;
}

.postition-icon-btn-accions {
   bottom: -10%;
   left: 80%;
   z-index: 999;
}

.postition-icon-key {
   bottom: -40%;
   left: 0%;
   z-index: 999;
}


.postition-icon-list {
   bottom: -40%;
   right: 0%;
   z-index: 999;
}

.postition-icon-dis {
   bottom: -75%;
   right: 0%;
   z-index: 999;
}


.postition-icon-pays {
   bottom: 10%;
   z-index: 999;
   left: 37%;
}


.focus-pay:focus {
   border: 10px solid rgb(240, 188, 18);
   border-radius: 30px;
}

.postition-icon-details {
   top: 0;
   left: 22%;
   z-index: 999;
}

.postition-icon-pays {
   bottom: -10%;
   z-index: 999;
   left: 5%;
}

.postition-icon-edit {
   top: 50%;
   z-index: 999;
   right: 50%;
}

details:focus {
   border: none;
}


/* !Revisar  */

.list-group-item {
   background-color: var(--bs-light);
   border: 1px solid #cfcfcf73;
   border-radius: 1px;
   -webkit-border-radius: 1px;
   -moz-border-radius: 1px;
   -ms-border-radius: 1px;
   -o-border-radius: 1px;
}

.list-group-radio .list-group-item:hover,
.list-group-radio .list-group-item:focus {
   color: var(--blanco) !important;
   background-color: var(--primary-vending) !important;
}

.list-group-item-check:hover+.list-group-item {
   background-color: var(--bs-light);
   border: 1px solid #007bff;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}

.list-group-item-check:checked+.list-group-item {
   color: #007bff;
   ;
   background-color: var(--bs-light);
   border: 1px solid #007bff;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}


.list-group-item-check[disabled]+.list-group-item,
.list-group-item-check:disabled+.list-group-item {
   pointer-events: none;
   filter: none;
   opacity: .5;
}




.msg-pay {
   margin: 25rem 0;
}

.msg-pay-bottom {
   margin: 10rem 0;
}

.msg-position {
   top: 10%;

}

.display-5-kk {

   font-size: calc(1.425rem + 2.1vw);
   font-weight: 300;
   line-height: 1.2;

}

/*Section: Responisve */

/* Title: Responisve Mobile */

/*~ +++++ Media Queries  ++++++ */
/* SECTION:DESKTOP or SELFT CHECKOUT */
@media screen and (max-width:1081px) {

   .kiosk-title-discount {
      font-size: 28px;
   }

   /* Utilits */
   ::-webkit-scrollbar {
      display: none;
   }

   .carousel-item img {
      width: 100%;
      height: 80vh;
      object-fit: cover;
      /*background-size: contain;
       */
      /* border: 5px solid white; */
   }

   .kiosk {
      display: flex;
      height: 100vh;
      display: flex;
      justify-content: center;

      /* border: 1px solid #a93c3c;
       background-color: var(--new-frame); */
   }

   /* Utilits */
   ::-webkit-scrollbar {
      display: none;
   }


   .kiosk-container {
      /* background-color: #ffffff00; */
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      /* align-content: flex-start; */
   }

   .kiosk-container_section_lista {
      padding: 0px 10px 135px 0px;
      /* border: 2px solid red; */
      overflow-x: auto;
   }

   .kiosk-container_section_title {
      font-size: 60px;
   }


   /*~ Cards */
   .kiosk-card-item {
      width: 18rem;
      height: 530px;
      border-radius: 15px !important;
      -webkit-border-radius: 15px !important;
      -moz-border-radius: 15px !important;
      -ms-border-radius: 15px !important;
      -o-border-radius: 15px !important;
   }

   .display-5-kk {
      font-size: 36px;
      margin: 0px 10px;
   }


   .kiosk-text-card {
      font-size: 36px;
      font-weight: 700;
   }

   .card-img-top {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 30px 30px 0px 0px;
      -webkit-border-radius: 30px 30px 0px 0px;
      -moz-border-radius: 30px 30px 0px 0px;
      -ms-border-radius: 30px 30px 0px 0px;
      -o-border-radius: 30px 30px 0px 0px;
   }

   .card-img-top>img {
      width: 100%;
      height: 315px;
      object-fit: cover;
      border-radius: 15px 15px 0px 0px !important;
      -webkit-border-radius: 15px 15px 0px 0px !important;
      -moz-border-radius: 15px 15px 0px 0px !important;
      -ms-border-radius: 15px 15px 0px 0px !important;
      -o-border-radius: 15px 15px 0px 0px !important;
   }

   /* Header of the Sections  */
   .kiosk-delivery_header_img {
      width: 220px;
      height: 110px;
      object-fit: cover;
      /* filter: drop-shadow(0 2px 5px rgba(158, 158, 158, 0.7)); */
   }



   /*~ Delivery Section  */

   .delivery {
      margin-top: 5px;
   }

   .kiosk-delivery_title {
      font-size: 50px;
      font-weight: 800;
      margin: 60px 0px 0px 0px;
   }

   .kiosk-delivery_btn {
      margin-top: 100px;
      display: flex;
      justify-content: center;
   }

   .kiosk-delivery_btns>button {
      margin: 20px;
      border-radius: 45px;
      -webkit-border-radius: 45px;
      -moz-border-radius: 45px;
      -ms-border-radius: 45px;
      -o-border-radius: 45px;
   }

   .kiosk-text-delivery {
      font-size: 60px;
      font-weight: 700;
   }

   .btn-img-delivery {
      /* width:215px;  */
      font-size: 280px;
   }

   .btn-card-delivery {
      color: #0D6EFD;
      padding: 14rem 60px;
   }


   /* ~ Categories */


   /** Btn Shopping Cart  */
   .btn-cart {
      fill: #fff;
      cursor: pointer;
      /* background-color: var(--primary-vending); */
      margin-top: 10px;
      padding: 24px;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }

   .kiosk-count {
      top: -12px;
      left: -22px;
      font-size: 20px;
   }

   .nav-button {
      font-size: 4rem;
      transform: translateY(-50%);
      background-color: rgba(255, 255, 255, .15);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      color: #fff;
      padding: 5px 23px;
      cursor: pointer;
   }

   /* ~ Buttons Exits */
   .button-ft_0 {
      fill: var(--blanco);
      cursor: pointer;
      background-color: var(--primario);
      width: 150px;
      height: 150px;
      padding: 20px 15px 20px 5px;
      border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
      -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
      -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
      -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
      -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   }


   .button-ft_1 {
      fill: var(--blanco);
      cursor: pointer;
      background-color: var(--primary-vending);
      width: 152px;
      height: 152px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }


   .button-ft,
   .button-ft_0:active,
   .button-ft_1:active {
      transform: scale(.92);
   }

   .kiosk-text-categories {
      font-size: 24px;
      font-weight: 800;
   }

   /*~ Productos */

   .image-empty {
      width: 100px;
      height: 100px;
      opacity: .50;
   }

   .kiosk-text-product {
      width: 315px;
      font-size: 35px;
      font-weight: 800;
   }

   .kiosk-card-body {
      flex: 1 1 auto;
      padding: 0px 0px;
      color: var(--bs-card-color);
   }

   .kiosk-text-card-product {
      font-size: 30px;
   }

   .card-img-top-products {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 10px 10px 0px 0px;
      -webkit-border-radius: 10px 10px 0px 0px;
      -moz-border-radius: 10px 10px 0px 0px;
      -ms-border-radius: 10px 10px 0px 0px;
      -o-border-radius: 10px 10px 0px 0px;
   }

   /*~ lIST CATEGORIES in porducts  */
   .container-list-kiosk {
      height: 345px;
      padding: 2px 0px;
      /* background-color: #000428; */
   }

   .container-list-kiosk label {
      width: auto;
      font-size: 40px;
      font-weight: 700;
   }

   .button-container-kk img {
      width: 150px;
      height: 150px;
      margin: 5px auto;
      display: block;
   }

   .container-list-kiosk ul {
      list-style: none;
      margin: 5px;
      padding: 0;
   }

   .container-list-kiosk li {
      display: inline-block;
      margin: 0px 0px;
   }

   .rounded-product-kiosk {
      border-radius: 1rem;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      -ms-border-radius: 1rem;
      -o-border-radius: 1rem;
   }

   /* Aplica un margen de 10px a todos los elementos de la lista */
   .kiosk-card-item_product {
      margin: 0px;
   }

   /* Aplica un margen izquierdo de 20px a los elementos mayores que el tercer hijo */
   .kiosk-card-item_product:nth-child(n + 4) {
      margin-top: 20px;
   }


   /*~ Info Products */

   .kiosk-segments-backgound {
      position: relative;
      width: 100%;
      height: 500px;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
      /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
   }

   .icon-info-product {
      font-size: 45px;
      color: #fff;
      background-color: #29292946;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      top: 0;
      right: 0;
      fill: #fff;
      cursor: pointer;
      /* background-color: var(--primary-vending); */
      margin-top: 11px;
      padding: 12px;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }

   .btn-close-img-info {
      font-size: 60px;
   }

   .img-modal-info {
      width: 100%;
      height: 50vh;

      object-fit: cover;
      border-radius: 60px;
      -webkit-border-radius: 60px;
      -moz-border-radius: 60px;
      -ms-border-radius: 60px;
      -o-border-radius: 60px;
   }

   .modal-xl-img-info {
      width: 975px !important;
   }

   .product-card-tv {
      width: 100%;
      height: 450px;
      /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
      position: absolute;
      bottom: 0;

   }


   .kiosk-description-product {
      font-size: 2rem;
      font-weight: 600;
   }

   /* ~List Crad Option Segments */
   label.radio-card {
      cursor: pointer;
   }

   label.radio-card .card-content-wrapper {
      scale: 1;
      background: #ffffff;
      border: solid 2px #e3e3e3;
      border-radius: 9px;
      width: 200px;
      height: 370px;
      padding: 5px;
      position: relative;
      overflow: hidden;

      display: grid;
      box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
      transition: 200ms linear;
      -webkit-border-radius: 9px;
      -moz-border-radius: 9px;
      -ms-border-radius: 9px;
      -o-border-radius: 9px;
   }

   label.radio-card .check-icon {
      width: 10px;
      height: 10px;
      display: flex;
      border: solid 2px #e3e3e3;
      border-radius: 50%;
      transition: 200ms linear;
      position: relative;
      justify-content: flex-end;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }

   label.radio-card .check-icon:before {
      content: "";

      /* background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 7px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear; */
      opacity: 0;
   }

   label.radio-card input[type=radio] {
      visibility: hidden;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
   }

   label.radio-card input[type=radio]:checked+.card-content-wrapper {
      border: 3px solid #0045f4;
      color: #000000;
      background-color: #4e80ff15;
      /*background: #055aaf;
     */
      /*background: -webkit-linear-gradient(to bottom, #042dbf, #0575E6);
     background: linear-gradient(to bottom, #042dbf, #0575E6);
     */

      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10x;
      -ms-border-radius: 10x;
      -o-border-radius: 10x;
   }

   label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon {
      background: #3057d5;
      border-color: #3056d5c5;
      transform: scale(1.2);
   }

   label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon:before {
      transform: scale(1);
      opacity: 1;
   }

   label.radio-card input[type=radio]:focus+.card-content-wrapper .check-icon {
      box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
      border-color: #3056d5;
   }


   label.radio-card .card-content img {
      width: 185px;
      height: 185px;
      margin-bottom: 10px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      scale: 1;
   }

   label.radio-card .card-content h4 {
      width: 180px;
      font-size: 28px;
      letter-spacing: -0.24px;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      color: #000;
      margin-bottom: 10px;
      font-weight: 800;
   }

   @keyframes marquee {
      0% {
         transform: translateX(0);
      }

      100% {
         transform: translateX(-100%);
      }
   }


   label.radio-card input[type=radio]:checked+.card-content-wrapper h4 {
      animation: marquee 5s infinite linear;
      white-space: nowrap;
      width: 100%;
      margin: 0;
      -webkit-animation: marquee 5s infinite linear;
   }

   label.radio-card .card-content h5 {
      font-size: 30px;
      line-height: 1.4;
      color: #217fd6;
      font-weight: 800;
      position: absolute;
      /* Establece el div como absoluto */
      bottom: 0;
      /* Lo coloca en la parte inferior del contenedor */
      left: 50%;
      /* Lo coloca en la mitad horizontal del contenedor */
      transform: translateX(-50%);
      /* Corrige el centrado horizontal */
      background-color: #ffffff00;
      padding: 0px;
   }



   .kiosk-lista-container-product {
      padding: 1px 0px 0px 0px;
      /* border: 2px solid red; */
      overflow-x: auto;
   }

   .container-card-content-wrapper {
      margin-bottom: 2px;

   }

   .container-card-content-wrapper:last-child {
      margin-bottom: 180px;
   }

   /* ~ Order Summary */
   .title-order-section {
      font-size: 3.5rem;
      font-weight: 800;
   }

   .button-discont {
      font-size: 2.5rem
   }

   .order-item {
      margin: 20px 10px;
   }

   .order-item:first-child {
      margin: 10px 10px;
   }

   .img-order-summary {
      width: 125px;
      height: 125px;
   }

   .title-product-text {
      font-size: 40px;
   }

   .summary-text {
      font-size: 30px;

   }

   .detail-text {
      font-size: 25px;
   }

   .btn-dash {
      background-color: red;
   }

   .btn-plus {
      background-color: green;
   }

   .btn-dash:active {
      transform: scale(.85);
      -webkit-transform: scale(.85);
      -moz-transform: scale(.85);
      -ms-transform: scale(.85);
      -o-transform: scale(.85);
   }

   .btn-plus:active {
      transform: scale(.85);
      -webkit-transform: scale(.85);
      -moz-transform: scale(.85);
      -ms-transform: scale(.85);
      -o-transform: scale(.85);
   }

   .count-items {
      font-size: 40px;
   }

   .dash,
   .plus {
      color: white;
      font-size: 15px;
      font-weight: 800;
      padding: 5px;
   }

   .btn-add-order {
      font-size: 36px;
      font-weight: 700;
      padding: 8px;
   }

   .btn-cancel-order {
      font-size: 48px;
      font-weight: 700;
      padding: 12px;
   }

   .btn-send-order {
      font-size: 48px;
      font-weight: 700;
      padding: 12px;
   }

   .dash-icon,
   .plus-icon {
      color: white;
      font-size: 38px;
      font-weight: 800;
      padding: 1px 15px;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
   }

   .text-count-orders {
      font-size: 25px;
   }

   .text-msg-empty-orders {
      font-size: 20px;
   }


   .text-total-order {
      font-size: 46px;
   }

   .text-subtitel-order {
      font-size: 29.5px;
   }

   /* Modal edit user  */
   .kiosk-key-letter {
      font-size: 70px;
      font-weight: 800;
      padding: 23px 25px;
      border: 2px solid var(--new-main-8);
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      margin: 2px;
   }

   .keypadPrimary-input_1 {
      color: var(--primary-vending);
      width: 100%;
      margin: 0;
      padding: 15px 0px;
      font-size: 25px;
      font-weight: 700;
      text-align: center;
      border: 2px solid rgb(226, 226, 226);
      letter-spacing: 25px;
   }

   .keyboard-pad>.num,
   .delete {
      color: #000;
      font-weight: 700;
      font-size: 65px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      padding: 50px 0;
      width: calc(33.333% - 8px);
      margin: 0 4px 8px;
      text-align: center;
      background-color: rgba(241, 241, 241, 0.8);
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      border: 1px solid rgb(226, 226, 226);
      box-shadow: 2px 2px 0 rgb(0, 0, 0, .2);
      inset: 4px -4px 0px #d4c69d;
      inset: -4px 4px 0px #beb28d;
   }

   .section-keyboard {
      top: 0;
      left: 0;
      width: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      padding: 7px;
   }

   .keyboard-pad>.keyboardKey {
      color: #000;
      font-weight: 800;
      text-align: center;
      font-size: 2rem;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      padding: 45px 0px;
      width: calc(12.333% - 8px);
      margin: 0 4px 8px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      border: 2px solid rgb(226, 226, 226);
      /*box-shadow: 2px 2px 0 rgb(0, 0, 0, .2);
    */
      inset: 4px -4px 0px #d4c69d;
      inset: -4px 4px 0px #beb28d;
   }

   .keyboard-pad>.delete {
      color: #000;
      font-weight: 800;
      text-align: center;
      font-size: 2rem;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      padding: 45px 0px;
      width: calc(20.333% - 8px);
      margin: 0 4px 8px;
      cursor: pointer;
      transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      border: 2px solid rgb(226, 226, 226);
      /* box-shadow: 2px 2px 0 rgb(0, 0, 0, .2); */
      inset: 4px -4px 0px #d4c69d;
      inset: -4px 4px 0px #beb28d;
   }

   .keyboard-pad>.num:hover {
      color: var(--primary-vending);
      background-color: #bae4dd37;
   }

   .num:hover {
      box-shadow: 3px 3px 2px rgb(0, 0, 0, .25);
      inset: 3px -3px 0px #d4c69d;
      inset: -3px 3px 0px #beb28d;
      transform: translate(3px, 3px);
      -webkit-transform: translate(3px, 3px);
      -moz-transform: translate(3px, 3px);
      -ms-transform: translate(3px, 3px);
      -o-transform: translate(3px, 3px);
      transition: 0.2s ease;
      -webkit-transition: 0.2s ease;
      -moz-transition: 0.2s ease;
      -ms-transition: 0.2s ease;
      -o-transition: 0.2s ease;
   }

   .delete:hover {
      box-shadow: 3px 3px 2px rgb(0, 0, 0, .25);
      inset: 3px -3px 0px #d49d9d;
      inset: -3px 3px 0px #be938d;
      transform: scale(-1px, -1px);
      -webkit-transform: scale(-1px, -1px);
      -moz-transform: scale(-1px, -1px);
      -ms-transform: scale(-1px, -1px);
      -o-transform: scale(-1px, -1px);
      transition: 0.2s ease;
      -webkit-transition: 0.2s ease;
      -moz-transition: 0.2s ease;
      -ms-transition: 0.2s ease;
      -o-transition: 0.2s ease;
   }

   .keypadPrimary-input_1 {
      color: var(--primary-vending);
      width: 100%;
      margin: 30px 0;
      padding: 15px 0;
      font-size: 60px;
      font-weight: 700;
      text-align: center;
      border: 2px solid rgb(226, 226, 226);
      letter-spacing: 25px;
   }


   /* ~Status Payments  */

   .msg-pay {
      margin: 1px 0;
   }

   .loading-status-pay {
      width: 250px;
      height: 250px;
   }

   .img-check-pay {
      width: 61px;
      height: 58px;
   }

   /* ~Modal discount */
   /* ~Modal Discounts */

   .kiosk-img-discount {
      width: 190px;
      height: 190px;
   }

   .kiosk-text-discount {
      font-size: 40px;
      font-weight: 800;
      margin: 10px 0px 0px 0px;
   }

   /* Paymnets */

   .radio-tile-group .input-container .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      /* background: #fff; */
      border: 5px solid #e8e8e8;
      border-radius: 30px;
      padding: 5rem 0rem;
      transition: transform 300ms ease;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
   }

   .radio-tile-group .input-container .radio-tile-label {
      text-align: center;
      font-size: 36px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
   }
}


/* SECTION:Tablet  */
@media screen and (max-width:768px) {

   .kiosk-title-discount {
      font-size: 38px;
   }

   /* Utilits */
   ::-webkit-scrollbar {
      display: none;
   }

   .kiosk {
      display: flex;
      height: 100vh;
      display: flex;
      justify-content: center;

      /* border: 1px solid #a93c3c;
       background-color: var(--new-frame); */
   }

   /* Utilits */
   ::-webkit-scrollbar {
      display: none;
   }


   .kiosk-container {
      background-color: #bcce17;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      /* align-content: flex-start; */
   }

   .kiosk-container_section_lista {
      padding: 50px 10px 80px 0px;
      /* border: 2px solid red; */
      overflow-x: auto;
   }

   .kiosk-container_section_title {
      font-size: 35px;
   }


   /*~ Cards */
   .kiosk-card-item {
      width: 15rem;
      height: 530px;
      border-radius: 15px !important;
      -webkit-border-radius: 15px !important;
      -moz-border-radius: 15px !important;
      -ms-border-radius: 15px !important;
      -o-border-radius: 15px !important;
   }

   .display-5-kk {
      font-size: 20px;
   }


   .kiosk-text-card {
      font-size: 36px;
      font-weight: 700;
   }

   .card-img-top {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 30px 30px 0px 0px;
      -webkit-border-radius: 30px 30px 0px 0px;
      -moz-border-radius: 30px 30px 0px 0px;
      -ms-border-radius: 30px 30px 0px 0px;
      -o-border-radius: 30px 30px 0px 0px;
   }

   .card-img-top>img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 15px 15px 0px 0px !important;
      -webkit-border-radius: 15px 15px 0px 0px !important;
      -moz-border-radius: 15px 15px 0px 0px !important;
      -ms-border-radius: 15px 15px 0px 0px !important;
      -o-border-radius: 15px 15px 0px 0px !important;
   }

   /* Header of the Sections  */
   .kiosk-delivery_header_img {
      width: 100px;
      height: 50px;
      object-fit: cover;
      /* filter: drop-shadow(0 2px 5px rgba(158, 158, 158, 0.7)); */
   }



   /*~ Delivery Section  */

   .delivery {
      margin-top: 5px;
   }

   .kiosk-delivery_title {
      font-size: 50px;
      font-weight: 800;
      margin: 60px 0px 0px 0px;
   }

   .kiosk-delivery_btn {
      margin-top: 100px;
      display: flex;
      justify-content: center;
   }

   .kiosk-delivery_btns>button {
      margin: 20px;
      border-radius: 45px;
      -webkit-border-radius: 45px;
      -moz-border-radius: 45px;
      -ms-border-radius: 45px;
      -o-border-radius: 45px;
   }

   .kiosk-text-delivery {
      font-size: 36px;
      font-weight: 700;
   }

   .btn-img-delivery {
      /* width:215px;  */
      font-size: 180px;
   }

   .btn-card-delivery {
      color: #0D6EFD;
      padding: 5.3rem 44px;
   }


   /* ~ Categories */


   /** Btn Shopping Cart  */
   .btn-cart {
      fill: #fff;
      cursor: pointer;
      /* background-color: var(--primary-vending); */
      margin-top: 10px;
      padding: 12px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }

   .kiosk-count {
      top: -12px;
      left: -22px;
      font-size: 20px;
   }

   .nav-button {
      font-size: 4rem;
      transform: translateY(-50%);
      background-color: rgba(255, 255, 255, .15);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      color: #fff;
      padding: 5px 23px;
      cursor: pointer;
   }

   /* ~ Buttons Exits */
   .button-ft_0 {
      fill: var(--blanco);
      cursor: pointer;
      background-color: var(--primario);
      width: 150px;
      height: 150px;
      padding: 20px 15px 20px 5px;
      border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
      -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
      -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
      -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
      -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   }


   .button-ft_1 {
      fill: var(--blanco);
      cursor: pointer;
      background-color: var(--primary-vending);
      width: 75px;
      height: 75px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }


   .button-ft,
   .button-ft_0:active,
   .button-ft_1:active {
      transform: scale(.92);
   }

   .kiosk-text-categories {
      font-size: 17px;
      font-weight: 800;
   }

   /*~ Productos */

   .image-empty {
      width: 100px;
      height: 100px;
      opacity: .50;
   }

   .kiosk-text-product {
      width: 104px;
      font-size: 15px;
      font-weight: 800;
   }

   .kiosk-card-body {
      flex: 1 1 auto;
      padding: 0px 0px;
      color: var(--bs-card-color);
   }

   .kiosk-text-card-product {
      font-size: 15px;
   }

   .card-img-top-products {
      width: 100%;
      height: 100px;
      object-fit: cover;
      border-radius: 10px 10px 0px 0px;
      -webkit-border-radius: 10px 10px 0px 0px;
      -moz-border-radius: 10px 10px 0px 0px;
      -ms-border-radius: 10px 10px 0px 0px;
      -o-border-radius: 10px 10px 0px 0px;
   }

   /*~ lIST CATEGORIES in porducts  */
   .container-list-kiosk {
      height: 81px;
      padding: 2px 0px;
   }

   .container-list-kiosk label {
      width: auto;
      font-size: 15px;
      font-weight: 700;
   }

   .button-container-kk img {
      width: 20px;
      height: 20px;
      margin: 5px;
   }

   .container-list-kiosk ul {
      list-style: none;
      margin: 5px;
      padding: 0;
   }

   .container-list-kiosk li {
      display: inline-block;
      margin: 0px 0px;
   }

   /* Aplica un margen de 10px a todos los elementos de la lista */
   .kiosk-card-item_product {
      margin: 0px;
   }

   /* Aplica un margen izquierdo de 20px a los elementos mayores que el tercer hijo */
   .kiosk-card-item_product:nth-child(n + 4) {
      margin-top: 20px;
   }


   /*~ Info Products */

   .kiosk-segments-backgound {
      position: relative;
      width: 100%;
      height: 300px;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
      /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
   }

   .product-card-tv {
      width: 100%;
      height: 300px;
      /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
      position: absolute;
      bottom: 0;

   }


   .kiosk-description-product {
      font-size: 15px;
      font-weight: 600;
   }

   /* ~List Crad Option Segments */
   label.radio-card {
      cursor: pointer;
   }

   label.radio-card .card-content-wrapper {
      scale: 1;
      background: #ffffff;
      border: solid 2px #e3e3e3;
      border-radius: 9px;
      width: 114px;
      height: 210px;
      padding: 5px;
      position: relative;
      display: grid;
      box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
      transition: 200ms linear;
      -webkit-border-radius: 9px;
      -moz-border-radius: 9px;
      -ms-border-radius: 9px;
      -o-border-radius: 9px;
   }

   label.radio-card .check-icon {
      width: 10px;
      height: 10px;
      display: flex;
      border: solid 2px #e3e3e3;
      border-radius: 50%;
      transition: 200ms linear;
      position: relative;
      justify-content: flex-end;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }

   label.radio-card .check-icon:before {
      content: "";

      /* background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 7px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear; */
      opacity: 0;
   }

   label.radio-card input[type=radio] {
      visibility: hidden;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
   }

   label.radio-card input[type=radio]:checked+.card-content-wrapper {
      border: 3px solid #0045f4;
      color: #000000;
      background-color: #4e80ff15;
      /*background: #055aaf;
     */
      /*background: -webkit-linear-gradient(to bottom, #042dbf, #0575E6);
     background: linear-gradient(to bottom, #042dbf, #0575E6);
     */

      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10x;
      -ms-border-radius: 10x;
      -o-border-radius: 10x;
   }

   label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon {
      background: #3057d5;
      border-color: #3056d5c5;
      transform: scale(1.2);
   }

   label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon:before {
      transform: scale(1);
      opacity: 1;
   }

   label.radio-card input[type=radio]:focus+.card-content-wrapper .check-icon {
      box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
      border-color: #3056d5;
   }

   label.radio-card .card-content img {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      scale: 1;
   }

   label.radio-card .card-content h4 {
      width: 99px;
      font-size: 14px;
      letter-spacing: -0.24px;
      text-align: start;
      font-weight: 500;
      color: #000;
      margin-bottom: 10px;
      font-weight: 800;
   }

   label.radio-card .card-content h5 {
      font-size: 18px;
      line-height: 1.4;

      color: #22a009;
      font-weight: 800;
   }

   .kiosk-lista-container-product {
      padding: 1px 0px 0px 0px;
      /* border: 2px solid red; */
      overflow-x: auto;
   }

   .container-card-content-wrapper {
      margin-bottom: 2px;

   }

   .container-card-content-wrapper:last-child {
      margin-bottom: 80px;
   }

   /* ~ Order Summary */
   .title-order-section {
      font-size: 1.7rem;
      font-weight: 800;
   }

   .button-discont {
      font-size: 1.2rem
   }

   .order-item {
      margin: 20px 10px;
   }

   .order-item:first-child {
      margin: 10px 10px;
   }

   .img-order-summary {
      width: 80px;
      height: 80px;
   }

   .title-product-text {
      font-size: 18px;
   }

   .summary-text {
      font-size: 14px;

   }

   .detail-text {
      font-size: 13px;
   }

   .btn-dash {
      background-color: red;
   }

   .btn-plus {
      background-color: green;
   }

   .btn-dash:active {
      transform: scale(.85);
      -webkit-transform: scale(.85);
      -moz-transform: scale(.85);
      -ms-transform: scale(.85);
      -o-transform: scale(.85);
   }

   .btn-plus:active {
      transform: scale(.85);
      -webkit-transform: scale(.85);
      -moz-transform: scale(.85);
      -ms-transform: scale(.85);
      -o-transform: scale(.85);
   }

   .count-items {
      font-size: 25px;
   }

   .dash,
   .plus {
      color: white;
      font-size: 15px;
      font-weight: 800;
      padding: 5px;
   }

   .btn-add-order {
      font-size: 16px;
      font-weight: 700;
      padding: 8px;
   }

   .btn-cancel-order {
      font-size: 18px;
      font-weight: 700;
      padding: 12px;
   }

   .btn-send-order {
      font-size: 18px;
      font-weight: 700;
      padding: 12px;
   }

   .dash-icon,
   .plus-icon {
      color: white;
      font-size: 15px;
      font-weight: 800;
      padding: 1px 5px;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
   }

   .text-count-orders {
      font-size: 15px;
   }

   .text-msg-empty-orders {
      font-size: 20px;
   }


   .text-total-order {
      font-size: 26px;
   }

   .text-subtitel-order {
      font-size: 13.5px;
   }

   /* Modal edit user  */
   .kiosk-key-letter {
      font-size: 12px;
      font-weight: 800;
      padding: 7px 11px;
      border: 2px solid var(--new-main-8);
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      margin: 2px;
   }

   .keypadPrimary-input_1 {
      color: var(--primary-vending);
      width: 100%;
      margin: .5rem 0;
      padding: 15px 0;
      font-size: 25px;
      font-weight: 700;
      text-align: center;
      border: 2px solid rgb(226, 226, 226);
      letter-spacing: 25px;
   }


   /* ~Status Payments  */

   .msg-pay {
      margin: 5rem 0;
   }

   /* ~Modal discount */
   /* ~Modal Discounts */

   .kiosk-img-discount {
      width: 190px;
      height: 190px;
   }

   .kiosk-text-discount {
      font-size: 25px;
      font-weight: 800;
      margin: 60px 0px 0px 0px;
   }

}


/* SECTION: MOBILE MEDUIM */
@media screen and (max-width:767px) {



   /* Utilits */
   ::-webkit-scrollbar {
      display: none;
   }

   .btn-close-img-info {
      font-size: 30px;
   }

   .icon-info-product {
      font-size: 25px;
      color: #fff;
      background-color: #29292946;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      top: 0;
      right: 0;
      fill: #fff;
      cursor: pointer;
      /* background-color: var(--primary-vending); */
      margin-top: 11px;
      padding: 12px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }


   .kiosk-container {
      background-color: #1723ce;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      /* align-content: flex-start; */
   }

   .kiosk-container_section_lista {
      padding: 50px 10px 80px 0px;
      /* border: 2px solid red; */
      overflow-x: auto;
   }

   .kiosk-container_section_title {
      font-size: 20px;
   }


   /*~ Cards */
   .kiosk-card-item {
      width: 9rem;
      height: 530px;
      border-radius: 15px !important;
      -webkit-border-radius: 15px !important;
      -moz-border-radius: 15px !important;
      -ms-border-radius: 15px !important;
      -o-border-radius: 15px !important;
   }

   .display-5-kk {
      font-size: 20px;
   }


   .kiosk-text-card {
      font-size: 20px;
      font-weight: 700;
   }

   .card-img-top {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 30px 30px 0px 0px;
      -webkit-border-radius: 30px 30px 0px 0px;
      -moz-border-radius: 30px 30px 0px 0px;
      -ms-border-radius: 30px 30px 0px 0px;
      -o-border-radius: 30px 30px 0px 0px;
   }

   .card-img-top>img {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 15px 15px 0px 0px !important;
      -webkit-border-radius: 15px 15px 0px 0px !important;
      -moz-border-radius: 15px 15px 0px 0px !important;
      -ms-border-radius: 15px 15px 0px 0px !important;
      -o-border-radius: 15px 15px 0px 0px !important;
   }

   /* Header of the Sections  */
   .kiosk-delivery_header_img {
      width: 100px;
      height: 50px;
      object-fit: cover;
      /* filter: drop-shadow(0 2px 5px rgba(158, 158, 158, 0.7)); */
   }



   /*~ Delivery Section  */

   .delivery {
      margin-top: 5px;
   }

   .kiosk-delivery_title {
      font-size: 20px;
      font-weight: 800;
      margin: 50px 0px 0px 0px;
   }

   .kiosk-delivery_btn {
      margin-top: 50px;
      display: flex;
      justify-content: center;
   }

   .kiosk-delivery_btns>button {
      margin: 20px;
      border-radius: 45px;
      -webkit-border-radius: 45px;
      -moz-border-radius: 45px;
      -ms-border-radius: 45px;
      -o-border-radius: 45px;
   }

   .kiosk-text-delivery {
      font-size: 25px;
      font-weight: 700;
   }

   .btn-img-delivery {
      /* width:215px;  */
      font-size: 100px;
   }

   .btn-card-delivery {
      color: #0D6EFD;
      padding: 5.5rem 23px;

   }


   /* ~ Categories */


   /** Btn Shopping Cart  */
   .btn-cart {
      fill: #fff;
      cursor: pointer;
      /* background-color: var(--primary-vending); */
      margin-top: 10px;
      padding: 12px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }

   .kiosk-count {
      top: -12px;
      left: -22px;
      font-size: 20px;
   }

   .nav-button {
      font-size: 2rem;
      transform: translateY(-50%);
      background-color: rgba(255, 255, 255, .15);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      color: #fff;
      padding: 4px 12px;
      cursor: pointer;
   }

   /* ~ Buttons Exits */
   .button-ft_0 {
      fill: var(--blanco);
      cursor: pointer;
      background-color: var(--primario);
      width: 150px;
      height: 150px;
      padding: 20px 15px 20px 5px;
      border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
      -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
      -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
      -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
      -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
   }


   .button-ft_1 {
      fill: var(--blanco);
      cursor: pointer;
      background-color: var(--primary-vending);
      width: 75px;
      height: 75px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }


   .button-ft,
   .button-ft_0:active,
   .button-ft_1:active {
      transform: scale(.92);
   }

   .kiosk-text-categories {
      font-size: 17px;
      font-weight: 800;
   }

   /*~ Productos */

   .image-empty {
      width: 100px;
      height: 100px;
      opacity: .50;
   }

   .kiosk-text-product {
      width: 104px;
      font-size: 15px;
      font-weight: 800;
   }

   .kiosk-card-body {
      flex: 1 1 auto;
      padding: 0px 0px;
      color: var(--bs-card-color);
   }

   .kiosk-text-card-product {
      font-size: 15px;
   }

   .card-img-top-products {
      width: 100%;
      height: 100px;
      object-fit: cover;
      border-radius: 10px 10px 0px 0px;
      -webkit-border-radius: 10px 10px 0px 0px;
      -moz-border-radius: 10px 10px 0px 0px;
      -ms-border-radius: 10px 10px 0px 0px;
      -o-border-radius: 10px 10px 0px 0px;
   }

   /*~ lIST CATEGORIES in porducts  */
   .container-list-kiosk {
      height: 110px;
      padding: 2px 0px;
   }

   .container-list-kiosk label {
      width: auto;
      font-size: 15px;
      font-weight: 700;
   }

   .button-container-kk img {
      width: 20px;
      height: 20px;
      margin: 5px;
      display: inline;
   }

   .container-list-kiosk ul {
      list-style: none;
      margin: 5px;
      padding: 0;
   }

   .container-list-kiosk li {
      display: inline-block;
      margin: 0px 0px;
   }

   /* Aplica un margen de 10px a todos los elementos de la lista */
   .kiosk-card-item_product {
      margin: 0px;
   }

   /* Aplica un margen izquierdo de 20px a los elementos mayores que el tercer hijo */
   .kiosk-card-item_product:nth-child(n + 4) {
      margin-top: 20px;
   }


   /*~ Info Products */

   .kiosk-segments-backgound {
      position: relative;
      width: 100%;
      height: 300px;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
      /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
   }

   .product-card-tv {
      width: 100%;
      height: 300px;
      /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
      position: absolute;
      bottom: 0;

   }


   .kiosk-description-product {
      font-size: 15px;
      font-weight: 600;
   }

   /* ~List Crad Option Segments */
   label.radio-card {
      cursor: pointer;
   }

   label.radio-card .card-content-wrapper {
      scale: 1;
      background: #ffffff;
      border: solid 2px #e3e3e3;
      border-radius: 9px;
      width: 114px;
      height: 210px;
      padding: 5px;
      position: relative;
      display: grid;
      box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
      transition: 200ms linear;
      -webkit-border-radius: 9px;
      -moz-border-radius: 9px;
      -ms-border-radius: 9px;
      -o-border-radius: 9px;
   }

   label.radio-card .check-icon {
      width: 10px;
      height: 10px;
      display: flex;
      border: solid 2px #e3e3e3;
      border-radius: 50%;
      transition: 200ms linear;
      position: relative;
      justify-content: flex-end;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }

   label.radio-card .check-icon:before {
      content: "";

      /* background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 7px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear; */
      opacity: 0;
   }

   label.radio-card input[type=radio] {
      visibility: hidden;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
   }

   label.radio-card input[type=radio]:checked+.card-content-wrapper {
      border: 3px solid #0045f4;
      color: #000000;
      background-color: #4e80ff15;
      /*background: #055aaf;
     */
      /*background: -webkit-linear-gradient(to bottom, #042dbf, #0575E6);
     background: linear-gradient(to bottom, #042dbf, #0575E6);
     */

      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10x;
      -ms-border-radius: 10x;
      -o-border-radius: 10x;
   }

   label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon {
      background: #3057d5;
      border-color: #3056d5c5;
      transform: scale(1.2);
   }

   label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon:before {
      transform: scale(1);
      opacity: 1;
   }

   label.radio-card input[type=radio]:focus+.card-content-wrapper .check-icon {
      box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
      border-color: #3056d5;
   }

   label.radio-card .card-content img {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      scale: 1;
   }

   label.radio-card .card-content h4 {
      width: 99px;
      font-size: 14px;
      letter-spacing: -0.24px;
      text-align: start;
      font-weight: 500;
      color: #000;
      margin-bottom: 10px;
      font-weight: 800;
   }

   label.radio-card .card-content h5 {
      font-size: 18px;
      line-height: 1.4;

      color: #22a009;
      font-weight: 800;
   }

   .kiosk-lista-container-product {
      padding: 1px 0px 0px 0px;
      /* border: 2px solid red; */
      overflow-x: auto;
   }

   .container-card-content-wrapper {
      margin-bottom: 2px;

   }

   .container-card-content-wrapper:last-child {
      margin-bottom: 80px;
   }

   /* ~ Order Summary */
   .title-order-section {
      font-size: 1.7rem;
      font-weight: 800;
   }

   .button-discont {
      font-size: 1.2rem
   }

   .order-item {
      margin: 20px 10px;
   }

   .order-item:first-child {
      margin: 10px 10px;
   }

   .img-order-summary {
      width: 80px;
      height: 80px;
   }

   .title-product-text {
      font-size: 18px;
   }

   .summary-text {
      font-size: 14px;

   }

   .detail-text {
      font-size: 13px;
   }

   .btn-dash {
      background-color: red;
   }

   .btn-plus {
      background-color: green;
   }

   .btn-dash:active {
      transform: scale(.85);
      -webkit-transform: scale(.85);
      -moz-transform: scale(.85);
      -ms-transform: scale(.85);
      -o-transform: scale(.85);
   }

   .btn-plus:active {
      transform: scale(.85);
      -webkit-transform: scale(.85);
      -moz-transform: scale(.85);
      -ms-transform: scale(.85);
      -o-transform: scale(.85);
   }

   .count-items {
      font-size: 25px;
   }

   .dash,
   .plus {
      color: white;
      font-size: 15px;
      font-weight: 800;
      padding: 5px;
   }

   .btn-add-order {
      font-size: 16px;
      font-weight: 700;
      padding: 8px;
   }

   .btn-cancel-order {
      font-size: 18px;
      font-weight: 700;
      padding: 12px;
   }

   .btn-send-order {
      font-size: 18px;
      font-weight: 700;
      padding: 12px;
   }

   .dash-icon,
   .plus-icon {
      color: white;
      font-size: 15px;
      font-weight: 800;
      padding: 1px 5px;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
   }

   .text-count-orders {
      font-size: 15px;
   }

   .text-msg-empty-orders {
      font-size: 20px;
   }


   .text-total-order {
      font-size: 26px;
   }

   .text-subtitel-order {
      font-size: 13.5px;
   }

   /* Modal edit user  */
   .kiosk-key-letter {
      font-size: 12px;
      font-weight: 800;
      padding: 7px 11px;
      border: 2px solid var(--new-main-8);
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      margin: 2px;
   }

   .keyboard-pad>.num,
   .delete {
      color: #000;
      font-weight: 700;
      font-size: 28px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      padding: 18px 0;
      width: calc(33.333% - 8px);
      margin: 0 4px 8px;
      text-align: center;
      background-color: rgba(241, 241, 241, 0.8);
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      border: 1px solid rgb(226, 226, 226);
      box-shadow: 2px 2px 0 rgb(0, 0, 0, .2);
      inset: 4px -4px 0px #d4c69d;
      inset: -4px 4px 0px #beb28d;
   }

   .keypadPrimary-input_1 {
      color: var(--primary-vending);
      width: 100%;
      margin: 30px 0px;
      padding: 15px 0;
      font-size: 25px;
      font-weight: 700;
      text-align: center;
      border: 2px solid rgb(226, 226, 226);
      letter-spacing: 25px;
   }


   /* ~Status Payments  */

   .msg-pay {
      margin: 1px 0;
   }

   .loading-status-pay {
      width: 100px;
      height: 120px;

   }

   .radio-tile-group .input-container .radio-tile-label {
      text-align: center;
      font-size: 17px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
   }

   .img-pay-met-kk {
      width: 135px;
      height: 100px;
      object-fit: contain;
   }

   /* ~Modal discount */
   /* ~Modal Discounts */

   .kiosk-img-discount {
      width: 128px;
      height: 128px;
   }

   .kiosk-text-discount {
      font-size: 20px;
      font-weight: 800;
      margin: 1px 0px 0px 0px;
   }

   .kiosk-delivery_header {
      padding: 3px;
      margin: 10px 0px;
      display: flex;
      justify-content: center;
      /* border: 2px solid #3c5ba9; */
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
   }

   .kiosk-subtitle-md {
      font-size: 19px;
      font-weight: 800;
   }


}

/* SECTION: MOBILE SMALL */
@media screen and (max-width:390px) {

   /* Utilits */
   ::-webkit-scrollbar {
      display: none;
   }

   .kiosk-container {
      background-color: #17ce20;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      /* align-content: flex-start; */
   }

   .kiosk-container_section_lista {
      padding: 50px 10px;
      /* border: 2px solid red; */
      overflow-x: auto;
   }

   .kiosk-card-item {
      width: 7rem;
      height: 530px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
   }

   .kiosk-text-card {
      font-size: 25px;
      font-weight: 700;
   }

   /*~ Delivery */
   .delivery {
      margin-top: 0px;
   }

   .kiosk-delivery_title {
      font-size: 17px;
      font-weight: 800;
      margin: 16px 0px 0px 0px;
   }


   .kiosk-delivery_btns>button {
      margin: 20px;
      border-radius: 40px;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      -ms-border-radius: 40px;
      -o-border-radius: 40px;
   }

   .kiosk-text-card {
      font-size: 13px;
      font-weight: 700;
   }

   .kiosk-text-delivery {
      font-size: 18px;
      font-weight: 700;
   }

   .btn-img-delivery {
      /* width:215px;  */
      font-size: 100px;
   }

   .btn-card-delivery {
      color: #0D6EFD;
      padding: 2.5rem 25px;

   }


   /** Btn Shopping Cart  */


   .kiosk-count {
      top: -8px;
      left: -16px;
      font-size: 15px;
   }

   .btn-cart {
      fill: #fff;
      cursor: pointer;
      /* background-color: var(--primary-vending); */
      margin-top: 10px;
      padding: 12px;
      width: 57px;
      height: 57px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }

   .button-ft_1 {
      fill: var(--blanco);
      cursor: pointer;
      background-color: var(--primary-vending);
      width: 60px;
      height: 60px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
   }

   .kiosk-text-categories {
      text-align: center;
      font-size: 14px;
      font-weight: 800;
   }


   /*~ lIST CATEGORIES in porducts  */

   .kiosk-delivery_header_img {
      width: 100px;
      height: 40px;
      object-fit: cover;
      /* filter: drop-shadow(0 2px 5px rgba(158, 158, 158, 0.7)); */
   }

   .container-list-kiosk {
      height: 88px;
      padding: 2px 0px;
   }

   .container-list-kiosk label {
      width: auto;
      font-size: 15px;
      font-weight: 700;
   }

   .kiosk-text-product {
      width: 90px;
      font-size: 15px;
      font-weight: 800;
   }

   .button-container-kk img {
      width: 20px;
      height: 20px;
      margin: 5px;
      display: inline;
   }

   .container-list-kiosk li {
      display: inline-block;
      margin: 0px 0px;
   }

   /*~ Info Products */

   .kiosk-segments-backgound {
      position: relative;
      width: 100%;
      height: 200px;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
      /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
   }

   .product-card-tv {
      border: 0px solid #000;
      width: 100%;
      height: 200px;
      /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
      position: absolute;
      bottom: 0;

   }

   .card-img-top>img {
      width: 100%;
      height: 120px;
      object-fit: cover;
      border-radius: 15px 15px 0px 0px !important;
      -webkit-border-radius: 15px 15px 0px 0px !important;
      -moz-border-radius: 15px 15px 0px 0px !important;
      -ms-border-radius: 15px 15px 0px 0px !important;
      -o-border-radius: 15px 15px 0px 0px !important;
   }


   .kiosk-description-product {
      font-size: 15px;
      font-weight: 600;
   }


   /* ~ Order Summary */
   details[open]>summary~* {
      display: hidden;
   }

   .title-order-section {
      font-size: 1.5rem;
      font-weight: 800;
   }

   .button-discont {
      font-size: 1.1rem
   }

   .order-item {
      margin: 20px 10px;
   }

   .order-item:first-child {
      margin: 10px 10px;
   }

   .img-order-summary {
      width: 70px;
      height: 70px;
   }

   .title-product-text {
      font-size: 16px;
   }

   .summary-text {
      font-size: 12px;

   }

   .detail-text {
      font-size: 12px;
   }

   .btn-dash {
      background-color: red;
   }

   .btn-plus {
      background-color: green;
   }

   .btn-dash:active {
      transform: scale(.85);
      -webkit-transform: scale(.85);
      -moz-transform: scale(.85);
      -ms-transform: scale(.85);
      -o-transform: scale(.85);
   }

   .btn-plus:active {
      transform: scale(.85);
      -webkit-transform: scale(.85);
      -moz-transform: scale(.85);
      -ms-transform: scale(.85);
      -o-transform: scale(.85);
   }

   .dash,
   .plus {
      color: white;
      font-size: 15px;
      font-weight: 800;
      padding: 5px;
   }

   .btn-add-order {
      font-size: 16px;
      font-weight: 700;
      padding: 8px;
   }

   .btn-cancel-order {
      font-size: 18px;
      font-weight: 700;
      padding: 12px;
   }

   .btn-send-order {
      font-size: 18px;
      font-weight: 700;
      padding: 12px;
   }

   .text-total-order {
      font-size: 18px;
   }

   .dash-icon,
   .plus-icon {
      color: white;
      font-size: 15px;
      font-weight: 800;
      padding: 1px 5px;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
   }

   .count-items {
      font-size: 20px;
   }

   .text-count-orders {
      font-size: 15px;
   }

   .text-msg-empty-orders {
      font-size: 17px;
   }

   .text-total-order {
      font-size: 20px;
   }

   .text-subtitel-order {
      font-size: 13.5px;
   }

   /* ~Paisanitos keyboard */
   .keyboard-pad>.num,
   .delete {
      color: #000;
      font-weight: 700;
      font-size: 19px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      padding: 17px 0;
      width: calc(33.333% - 8px);
      margin: 0 4px 8px;
      text-align: center;
      background-color: rgba(241, 241, 241, 0.8);
      cursor: pointer;
      transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      border: 1px solid rgb(226, 226, 226);
      box-shadow: 2px 2px 0 rgb(0, 0, 0, .2);
      inset: 4px -4px 0px #d4c69d;
      inset: -4px 4px 0px #beb28d;
   }


   /* ~Payments Methods */

   .radio-tile-group .input-container .radio-tile-label {
      text-align: center;
      width: 120px;
      /* Ancho máximo del contenedor */
      white-space: nowrap;
      /* Evita que el texto se divida en varias líneas */
      overflow: hidden;
      /* Oculta el contenido que se desborda del contenedor */
      text-overflow: ellipsis;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;

   }

   .radio-tile-group .input-container .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      /* background: #fff; */
      border: 5px solid #e8e8e8;
      border-radius: 30px;
      padding: 25px 12px;
   }

   .img-pay-met-kk {
      width: 135px;
      height: 70px;
      object-fit: contain;
   }

   /* ~Modal User */
   .kiosk-key-letter {
      font-size: 12px;
      font-weight: 800;
      padding: 7px 10px;
      border: 2px solid var(--new-main-8);
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      margin: 2px;
   }

   .keypadPrimary-input_1 {
      color: var(--primary-vending);
      width: 100%;
      margin: .5rem 0;
      padding: 15px 0;
      font-size: 25px;
      font-weight: 700;
      text-align: center;
      border: 2px solid rgb(226, 226, 226);
      letter-spacing: 25px;
   }

   /* ~Status Payments  */

   .msg-pay {
      margin: 0rem 0;
   }

   .img-check-pay {
      width: 61px;
      height: 58px;
   }


   /* ~Modal Discounts */

   .kiosk-img-discount {
      width: 100px;
      height: 100px;
   }

   .kiosk-text-discount {
      font-size: 18px;
      font-weight: 800;
      margin: 1px 0px 0px 0px;
   }

   .kiosk-delivery_header {
      padding: 3px;
      margin: 10px 0px;
      display: flex;
      justify-content: center;
      /* border: 2px solid #3c5ba9; */
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
   }

   .kiosk-subtitle-md {
      font-size: 16px;
      font-weight: 800;
   }


   .loading-status-pay {
      width: 60px;
      height: 60px;

   }

}

@media (width <=640px) {


   .kiosk-header-img-categories {
      width: 60px;
      filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
      -webkit-filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
   }



   /* .btn-language-kk {
        width: 120px; 
    } */

   .display-5-kk {

      font-size: 20px;

   }



   .kiosk-text-title-lang {
      font-size: 25px;
   }


   .kiosk-lista_items:last-child {
      padding-right: 20px;
   }

   .count-kiosk {
      font-size: 1.5rem;
   }




   .icon-delete-kk {
      font-size: 20px;
   }

}



/* Section: +++++ Animations BLOCK   +++++ */
/* Title: +++++ Animation +++++ */

.scale-up-left {
   animation: scale-up-left 0.4s;
}

@keyframes scale-up-left {
   0% {
      transform: scale(.5);
      transform-origin: left center
   }

   100% {
      transform: scale(1);
      transform-origin: left center
   }
}



.bounce-left {
   animation: bounce-left 1.2s linear both;
   transition: 0.5s ease-in
}

@keyframes bounce-left {
   0% {
      transform: translateX(-48px);
      animation-timing-function: ease-in;
      opacity: 1
   }

   24% {
      opacity: 1
   }

   40% {
      transform: translateX(-26px);
      animation-timing-function: ease-in
   }

   65% {
      transform: translateX(-13px);
      animation-timing-function: ease-in
   }

   82% {
      transform: translateX(-6.5px);
      animation-timing-function: ease-in
   }

   93% {
      transform: translateX(-4px);
      animation-timing-function: ease-in
   }

   25%,
   55%,
   75%,
   87%,
   98% {
      transform: translateX(0);
      animation-timing-function: ease-out
   }

   100% {
      transform: translateX(0);
      animation-timing-function: ease-out;
      opacity: 1
   }
}

.title-section {
   bottom: 0;
   left: 0;
   margin: auto;
}