/* Section: Variables */
/* * Global Vars  #0025B8*/
/* Texts */
.title-pos {
   font-size: 30px;
}

.subtitle-pos {
   font-size: 25px;
}

.paragraph {
   font-size: 15px;
}

/* Title: +++++++ Backgrounds +++++++ */

.bg-brand-primary {
   /* background: #396afc;
   background: -webkit-linear-gradient(to bottom, #2948ff, #396afc);
   background: linear-gradient(to bottom, #2948ff, #396afc); */

   background: #055aaf;
   background: -webkit-linear-gradient(to top, #042dbf, #0575E6);
   background: linear-gradient(to top, #042dbf, #0575E6);

}

.bg-brand-smartpay {
   background: #2bbe3a;
   background: -webkit-linear-gradient(to top, #26a337, #2bbe3a);
   background: linear-gradient(to top, #26a337, #2bbe3a);
}

.bg-brand-night {
   background: #000428;
   background: -webkit-linear-gradient(to bottom, #004e92, #000428);
   background: linear-gradient(to bottom, #004e92, #000428);
}

.bg-brand-light-blue {
   background: #00c6ff;
   background: -webkit-linear-gradient(to top, #0072ff, #00c6ff);
   background: linear-gradient(to top, #0072ff, #00c6ff);
}

.bg-success-pos {
   background: red;
}

body {
   font-family: "Nunito", sans-serif;
   overflow-x: hidden;
   scrollbar-width: thin;
}

.img-shopping-card {
   width: 80px;
   height: 80px;
}


/* Section: Layout General */
/* Estilos para el contenedor padre */
.pos-container {
   display: flex;
   height: 100vh;
}

.pos-container-list-orders,
.pos-container-view {
   height: 100%;
   /* background-color: #D9D9D9; */
}

.container-pos-left-xl {
   /* El contenedor derecho ocupará 3 veces el espacio del contenedor izquierdo */
   overflow: auto;
   width: 92%;
}

.container-pos-right {
   /* flex: 3; */
   /* El contenedor derecho ocupará 3 veces el espacio del contenedor izquierdo */
   overflow: auto;
   width: 68%;
   background-color: #F5F8FA;
}

.container-pos-left {
   /* flex: 1; */
   /* El contenedor derecho ocupará 3 veces el espacio del contenedor izquierdo */
   overflow: auto;
   width: 32%;
}

/* ~Header Shopping Cart  */
.header-pos {
   margin-top: 52px
}

.btn-dash {
   background-color: red;
}

.btn-plus {
   background-color: green;
}

.btn-dash:active {
   transform: scale(.85);
   -webkit-transform: scale(.85);
   -moz-transform: scale(.85);
   -ms-transform: scale(.85);
   -o-transform: scale(.85);
}

.btn-plus:active {
   transform: scale(.85);
   -webkit-transform: scale(.85);
   -moz-transform: scale(.85);
   -ms-transform: scale(.85);
   -o-transform: scale(.85);
}

.count-items {
   font-size: 25px;
}

.dash,
.plus {
   color: white;
   font-size: 15px;
   font-weight: 800;
   padding: 5px;
}

.dash-icon-pos,
.plus-icon-pos {
   color: white;
   font-size: 16px;
   font-weight: 800;
   padding: 6px 4px;
   border-radius: 20px;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   -ms-border-radius: 20px;
   -o-border-radius: 20px;
}

.count-items-pos {
   font-size: 15px;
}

.list-discount-framer {

   height: 700px;
}

.list-prdtc-order-framer {
   height: 700px;
   width: 365px;
}

/* Title Radio */

.list-group-item-check:checked+.list-group-item {
   color: #007bff;
   background-color: var(--bs-light);
   border: 2px solid #007bff;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}

.list-pre-orders-inputs input[type="radio"] {
   display: none;
}

.list-pre-orders-inputs input[type="radio"]+label:before {
   content: "";
   display: inline-block;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   border: 2px solid #f1f1f1;
   margin-right: 10px;
}

.list-pre-orders-inputs input[type="radio"]:checked+label:before {
   background-color: #007bff;
}

/* Establecer el contenido del icono */
.list-pre-orders-inputs input[type="radio"]:checked+label:before {
   content: "\2713";
   /* Código unicode del icono (por ejemplo, ✔) */
   font-size: 14px;
   /* Ajustar el tamaño del icono según sea necesario */
   color: rgb(255, 255, 255);
   /* Color del icono */
   text-align: center;
   line-height: 18px;
   /* Centrar verticalmente el icono */
}

.list-pre-orders-inputs label {
   position: relative;
   padding-left: 0px;
}

.list-pre-orders-inputs input[type="radio"]+label:before {
   position: absolute;
   right: -3px;
   top: 5%;
   transform: translateY(-50%);
}

.list-group-item {
   background-color: var(--blanco);
   border: 1px solid #cfcfcf73;
   border-radius: 1px;
   -webkit-border-radius: 1px;
   -moz-border-radius: 1px;
   -ms-border-radius: 1px;
   -o-border-radius: 1px;
}

/*title: checkbox */
.list-pre-orders-inputs input[type="checkbox"] {
   display: none;
}

.list-pre-orders-inputs input[type="checkbox"]+label:before {
   content: "";
   display: inline-block;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   border: 2px solid #f1f1f1;
   margin-right: 10px;
}

.list-pre-orders-inputs input[type="checkbox"]:checked+label:before {
   background-color: #007bff;
}

/* Establecer el contenido del icono */
.list-pre-orders-inputs input[type="checkbox"]:checked+label:before {
   content: "\2713";
   /* Código unicode del icono (por ejemplo, ✔) */
   font-size: 14px;
   /* Ajustar el tamaño del icono según sea necesario */
   color: rgb(255, 255, 255);
   /* Color del icono */
   text-align: center;
   line-height: 18px;
   /* Centrar verticalmente el icono */
}

.list-pre-orders-inputs label {
   position: relative;
   padding-left: 0px;
}

.list-pre-orders-inputs input[type="checkbox"]+label:before {
   position: absolute;
   right: -6px;
   top: 15%;
   transform: translateY(-50%);
}

.list-group-item {
   background-color: var(--blanco);
   border: 1px solid #cfcfcf73;
   border-radius: 1px;
   -webkit-border-radius: 1px;
   -moz-border-radius: 1px;
   -ms-border-radius: 1px;
   -o-border-radius: 1px;
}

/* ~Info Products modal */

/* Title Radio */

.list-group-item-check:checked+.list-group-item {
   color: #007bff;
   background-color: var(--bs-light);
   border: 2px solid #007bff;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}

.list-info-products-inputs input[type="radio"] {
   display: none;
}

.list-info-products-inputs input[type="radio"]+label:before {
   content: "";
   display: inline-block;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   border: 2px solid #f1f1f1;
   margin-right: 10px;
}

.list-info-products-inputs input[type="radio"]:checked+label:before {
   background-color: #007bff;
}

/* Establecer el contenido del icono */
.list-info-products-inputs input[type="radio"]:checked+label:before {
   content: "\2713";
   /* Código unicode del icono (por ejemplo, ✔) */
   font-size: 14px;
   /* Ajustar el tamaño del icono según sea necesario */
   color: rgb(255, 255, 255);
   /* Color del icono */
   text-align: center;
   line-height: 18px;
   /* Centrar verticalmente el icono */
}

.list-info-products-inputs label {
   position: relative;
   padding-left: 0px;
   width: 300px;
}

.list-info-products-inputs input[type="radio"]+label:before {
   position: absolute;
   right: -3px;
   top: 13%;
   transform: translateY(-50%);
}

/* Title Checkbox */

.list-group-item-check:checked+.list-group-item {
   color: #007bff;
   background-color: var(--bs-light);
   border: 2px solid #007bff;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}

.list-info-products-inputs input[type="checkbox"] {
   display: none;
}

.list-info-products-inputs input[type="checkbox"]+label:before {
   content: "";
   display: inline-block;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   border: 2px solid #f1f1f1;
   margin-right: 10px;
}

.list-info-products-inputs input[type="checkbox"]:checked+label:before {
   background-color: #007bff;
}

/* Establecer el contenido del icono */
.list-info-products-inputs input[type="checkbox"]:checked+label:before {
   content: "\2713";
   /* Código unicode del icono (por ejemplo, ✔) */
   font-size: 14px;
   /* Ajustar el tamaño del icono según sea necesario */
   color: rgb(255, 255, 255);
   /* Color del icono */
   text-align: center;
   line-height: 18px;
   /* Centrar verticalmente el icono */
}

.list-info-products-inputs label {
   position: relative;
   padding-left: 0px;
   width: 300px;
}

.list-info-products-inputs input[type="checkbox"]+label:before {
   position: absolute;
   right: -3px;
   top: 13%;
   transform: translateY(-50%);
}

/*Title Modal Payments  */

.list-pre-orders-inputs-pays input[type="radio"] {
   display: none;
}

.list-pre-orders-inputs-pays input[type="radio"]+label:before {
   content: "";
   display: inline-block;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   border: 2px solid #f1f1f1;
   margin-right: 10px;
}

.list-pre-orders-inputs-pays input[type="radio"]:checked+label:before {
   background-color: #20b852;
}

/* Establecer el contenido del icono */
.list-pre-orders-inputs-pays input[type="radio"]:checked+label:before {
   content: "\2713";
   /* Código unicode del icono (por ejemplo, ✔) */
   font-size: 19px;
   /* Ajustar el tamaño del icono según sea necesario */
   color: rgb(255, 255, 255);
   /* Color del icono */
   text-align: center;
   line-height: 18px;
   /* Centrar verticalmente el icono */
}

.list-pre-orders-inputs-pays label {
   position: relative;
   padding-left: 0px;
}

.list-pre-orders-inputs-pays input[type="radio"]+label:before {
   position: absolute;
   right: -3px;
   top: 23%;
   transform: translateY(-50%);
}


.list-group-item-check-pay>img {
   background-color: #20b852;
   ;

}

.list-group-item-check-pay {
   position: absolute;
   clip: rect(0, 0, 0, 0);
   pointer-events: none;

}


.list-group-item-check-pay:hover+.list-group-item {
   background-color: var(--bs-light);
   border: 1px solid #20b852;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}

.list-group-item-check-pay:checked+.list-group-item {
   color: #20b852;
   ;
   background-color: var(--bs-light);
   border: 2px solid #20b852;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}

.list-group-item-check-pay:not(:checked)+.list-group-item img {
   background-color: #20b85352;
}

.list-group-item-check-pay:checked+.list-group-item img {
   background-color: #20b852;
}

.list-group-item-check-pay[disabled]+.list-group-item,
.list-group-item-check-pay:disabled+.list-group-item {
   pointer-events: none;
   filter: none;
   opacity: .5;
}


/* ***********************************
 ******** Cards Produts Kiosk *********
 **************************************/

/* Title Radio */
.list-group-item-check:checked+.list-group-item {
   color: #007bff;
   width: 300px;
   background-color: var(--bs-light);
   border: 2px solid #007bff;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}

.list-info-products-inputs input[type="radio"] {
   display: none;
}










/* Modal Pre Orders  */


.list-total-pre-order-modal>small {
   margin: 5px 2px;

}

.list-pre-order-framer {
   height: 450px;
   width: 410px;
}

.list-modal-pays-framer {
   height: 220px;
   width: 410px;
}

/* End: Layout General */

.text-subtitel-order-pos {
   font-size: 18px;
}

.summary-text {
   font-size: 17px;
}

.list-discount-framer-2 {
   height: 300px;
}


.list-create-pre-acount-framer {
   height: 305px;
}

/* Title: Responisve Mobile */
/*~ +++++ Media Queries  ++++++ */
@media (max-width:1280px) {

   .list-discount-framer {

      height: 345px;
   }

   .list-create-pre-acount-framer {
      height: 320px;
   }

   .list-prdtc-order-framer {
      height: 345px;
      width: 365px;
   }
}

@media(max-width:1180px) {
   .header-pos {
      margin-top: 85px;
   }


}

/* SECTION:DESKTOP or pos */
@media (max-width:1080px) {
   .bg-body-sp {
      background-color: #f7f7f7;
   }

   .text-subtitel-order-pos {
      font-size: 18px;
   }

   .list-discount-framer {

      height: 345px;
   }

   .list-create-pre-acount-framer {
      height: 345px;
   }
}

/* SECTION:DESKTOP or pos */
@media(max-width:960px) {
   .bg-body-sp {
      background-color: #ffffff;
   }

   .list-discount-framer {

      height: 345px;
   }


}

/* SECTION:Micros or pos */
@media(max-width:800px) {
 
/* 
   .fs-label-1 {
      font-size: 30px;
   }

   .fs-label-2 {
      font-size: 25px;
   }

   .fs-label-3 {
      font-size: 20px;
   }

   .buttons-label {
      font-size: 30px;

   }

   .user-name {
      font-size: 25px !important;
   } */

   .header-pos {
      margin-top: 90px;
   }

   .navbar-logo-post {
      height: 80px !important;
   }

   .login-wrapper {
    border: 1px solid #c0c0c0;
    height: 400px !important;
    width: 100%;
    overflow-y: scroll;
    margin: 5px 0;
    border-radius: 2px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.form-signin-sp {
    height: 100%;
    width: 660px !important;
    padding: 1px 20px;
    /* padding: 30px 40px; */
    border: 2px solid #d5ffd8;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    transition: all .50s ease;
    -webkit-transition: all .50s ease;
    -moz-transition: all .50s ease;
    -ms-transition: all .50s ease;
    -o-transition: all .50s ease;
}

button.control {
    margin-top: 10px;
    cursor: pointer;
    width: 100%;
    height: 40px !important;
    padding: 0 16px;
    background: var(--primario);
    color: #f9f9f9;
    border: 0;
    font-family: inherit;
    font-size: 20px !important;
    font-weight: 800 !important;
    text-align: center;
    letter-spacing: 2px;
    transition: all 0.375s;
}

   .img-shopping-card {
      width: 180px;
      height: 180px;
   }

   .container-pos-left {
      overflow: auto;
      width: 100%;
   }

   .container-pos-right {
      /* flex: 3; */
      overflow: auto;
      width: 0%;
      background-color: #F5F8FA;
   }

   .card-title-product-mobile {
      font: 30px;
      color: #000;
   }

   .mobile-bar {
      display: block;
   }

   .list-menu-framer {
      height: 900px;
      width: 825px;
   }
   .list-modal-pays-framer {
    height: 420px;
   
}

.list-discount-framer-2 {
   height: 665px;
}

.list-pre-order-framer {
    height: 450px;
    width: 100%;
}

.sumamary-text {
    font-size: 30px !important;
    font-weight: bold;
    color: #0a89fe;
    padding: 0px 40px !important;
}
   .modal {
      --bs-modal-zindex: 1055;
      --bs-modal-width: 770px !important;
      --bs-modal-padding: 1rem;
      --bs-modal-margin: 0.5rem;
      --bs-modal-color: ;
      --bs-modal-bg: var(--bs-body-bg);
      --bs-modal-border-color: var(--bs-border-color-translucent);
      --bs-modal-border-width: var(--bs-border-width);
      --bs-modal-border-radius: var(--bs-border-radius-lg);
      --bs-modal-box-shadow: var(--bs-box-shadow-sm);
      --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) -(var(--bs-border-width)));
      --bs-modal-header-padding-x: 1rem;
      --bs-modal-header-padding-y: 1rem;
      --bs-modal-header-padding: 1rem 1rem;
      --bs-modal-header-border-color: var(--bs-border-color);
      --bs-modal-header-border-width: var(--bs-border-width);
      --bs-modal-title-line-height: 1.5;
      --bs-modal-footer-gap: 0.5rem;
      --bs-modal-footer-bg: ;
      --bs-modal-footer-border-color: var(--bs-border-color);
      --bs-modal-footer-border-width: var(--bs-border-width);
      position: fixed;
      top: 0;
      left: 0;
      z-index: var(--bs-modal-zindex);
      display: none;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      outline: 0;
   }

   .icon-mobile {
      font-size: 40px;
   }

   .label-responsive {
      display: block;
      font-size: 22px;
   }

   .icon-delete {
   font-size: 45px;
}

}

/* SECTION: MOBILE MEDUIM */
@media (max-width:767px) {

   .bg-body-sp {
      background-color: #f1f1f1;
   }

   /* Title: Layout General */
   .container-pos-right {
      overflow: hidden;
      visibility: hidden;
      width: 0%;
      background-color: #f5f8fa8a;
   }

   .container-pos-left,
   .container-pos-left-xl {
      overflow: auto;
      width: 100%;
      background-color: #F5F8FA;
   }

   /* ~Header Shopping Cart  */
   .header-pos {
      margin-top: 90px
   }

   /* ~card sHOPPING CART */

   .title-product-cart {
      font-size: 16px;
      font-weight: 700;
      width: 135px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
   }

   .switch-scale {

      scale: 0.9;
   }

   .img-pay-met {
      width: 60px;
      height: 60px;
      object-fit: contain;
   }

   .text-subtitel-order-pos {
      font-size: 18px;
   }

   .btn-dash {
      background-color: red;
   }

   .btn-plus {
      background-color: green;
   }

   .btn-dash:active {
      transform: scale(.85);
      -webkit-transform: scale(.85);
      -moz-transform: scale(.85);
      -ms-transform: scale(.85);
      -o-transform: scale(.85);
   }

   .btn-plus:active {
      transform: scale(.85);
      -webkit-transform: scale(.85);
      -moz-transform: scale(.85);
      -ms-transform: scale(.85);
      -o-transform: scale(.85);
   }

   .count-items {
      font-size: 25px;
   }

   .dash,
   .plus {
      color: white;
      font-size: 15px;
      font-weight: 800;
      padding: 5px;
   }

   .summary-text {
      font-size: 14px;

   }

   .list-discount-framer {

      height: 420px;
   }

   .list-prdtc-order-framer {
      height: 300px;
      width: 325px;
   }

   .text-description-info {

      display: none;
      visibility: hidden;
   }

   .img-loading-discount {
      width: 250px;

   }


   .button-container {
      background-color: #fff;
      display: inline-block;
      vertical-align: top;
   }

   .button-container img {
      width: 30px;
      height: 30px;
      margin-right: 5px;
   }

   .button-container label {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      white-space: nowrap;
      font-size: 15px;
      padding: 4px 10px;
   }



   .btn-check {
      background-color: #fff;
   }

   .list-total-pre-order-modal>small {
      margin: 5px 0px;

   }

   .list-pre-order-framer {
      height: 268px;
      width: 100%;
   }

   .list-menu-framer {
      height: 460px;
      width: 325px;
   }

   .list-modal-pays-framer {
      height: 300px;
      width: 100%;
   }

}

/* SECTION: MOBILE SMALL */
@media (max-width:390px) {
  
   .bg-body-sp {
      background-color: #ffffff;
   }

   .list-discount-framer {

      height: 420px;
   }

   .list-modal-pays-framer {
      height: 279px;
      width: 100%;
   }

}